import React, { useState } from 'react'
import { Button, Radio, Box as MUIBox, TextField, Collapse } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Circle } from 'react-feather'
import "./Settings.scss"
import { useDispatch, useSelector } from 'react-redux'
import { setColorBlind, setDisplayMode, setUnitMeasurement } from '../../store/action'
import { errorToaster, successToaster } from '../../Utils/messageToast'
import { passwordChange } from '../../services'
import { setLoading } from '../../store/action'
import { withApollo } from '@apollo/client/react/hoc'

const PRIMARY_COLOR = "#008F85"
const LIGHT_DISPLAY_MODE = "light"
const DARK_DISPLAY_MODE = "dark"

function Settings(props) {
  const dispatch = useDispatch()
  const displayMode = useSelector(state => state.displayMode)
  const colorBlind = useSelector(state => state.colorBlind)
  const unitMeasurement = useSelector(state => state.unitMeasurement)
  const [settingChanged, setSettingChanged] = useState(false)

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [currentPwsError, setCurrentPwsError] = useState('')
  const [newPswError, setNewPswError] = useState(false)
  const [passwordOpen, setPasswordOpen]= useState(false)

  const handleDisplayChange = (e) => {
    dispatch(setDisplayMode(e.target.value))
    setSettingChanged(true)
  }

  const handleColorBlindChange = e => {
    let isChecked = e.target.checked
    dispatch(setColorBlind(isChecked))
    setSettingChanged(true)
  }

  const handleUnitChange = e => {
    let unit = e.target.value
    if(unit === 'm'){
      dispatch(setUnitMeasurement('m'))
    }else{
      dispatch(setUnitMeasurement('ft'))
    }
    setSettingChanged(true)
  }

  const miniCardComponent = (mode) => {
    return <>
      <div 
        className={'card shadow ' + mode + " "+ (colorBlind ? 'color-blind-card' : "")} 
        onClick={() => {
          dispatch(setDisplayMode(mode))
          setSettingChanged(true)
        }}
      >
        <div className='mini-card center'>
          <div className='w-100'>
            <div className='center'>
              <Circle fill={PRIMARY_COLOR} color='transparent' size={15}/>
              <div className='mini-rec'></div>
            </div>

            <div className='center mt-2'>
              <Circle fill={PRIMARY_COLOR} color='transparent' size={15}/>
              <div className='mini-rec'></div>
            </div>
          </div>
        </div>

        <div className='mini-card half center'></div>
      </div>
    </>
  }

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'currentPassword':
        setCurrentPassword(value);
        break;
      case 'newPassword':
        setNewPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  }

  const handlePasswordExpand = (e) => {
    setPasswordOpen(!passwordOpen)
  }

  const submitPasswordChange = async (e) => {
    e.preventDefault()

    if(newPassword !== confirmPassword) {
      errorToaster("Password does not match")
      return
    }

    if(newPassword === currentPassword) {
      errorToaster("New password and current password shoudld not be the same")
      return
    }

    dispatch(setLoading(true))
    try {
      const result = await passwordChange(props, {
        oldPassword: currentPassword,
        newPassword1: newPassword,
        newPassword2: confirmPassword
      })
      if(!result.passwordChange.success) {
        if(result.passwordChange.errors?.oldPassword) {
          setCurrentPwsError(result.passwordChange.errors?.oldPassword[0].message)
          errorToaster(result.passwordChange.errors?.oldPassword[0].message)
        }
        if(result.passwordChange.errors?.newPassword2) {
          setNewPswError(true)
          for(let e of result.passwordChange.errors?.newPassword2) {
            errorToaster(e.message)
          }
        }
      }
      else {
        successToaster("Password changed successfully!")
        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setCurrentPwsError("")
        setNewPswError(false)
      }
    } catch (error) {
      console.log(error)
      errorToaster("Unable to change the password at the moment. Please try again later.")
    }
    dispatch(setLoading(false))

  }

  return (
    <div>
      <h4 className='my-3'><strong>Settings</strong></h4>
      <div className='mt-4'>
        <div className='w-100'>
        <MUIBox sx={{ width: '100%' }}>
          <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Button
              onClick={handlePasswordExpand}
              endIcon={passwordOpen ? <ExpandLess />:<ExpandMore/>}
              fullWidth
              sx={{
                justifyContent: 'space-between',
                textTransform: 'none',
                color: displayMode === DARK_DISPLAY_MODE ? "white" : 'black',
                margin: 0
              }}
            >
              Change Password
            </Button>          
          </div>
          <Collapse in={passwordOpen}>
            <MUIBox
              component="form"
              sx = {{
                display: "flex",
                flexDirection: "column"
              }}
              onSubmit={submitPasswordChange}
            >
              <TextField
                label="Current Password"
                variant="outlined"
                type='password'
                name = "currentPassword"
                value={currentPassword}
                onChange = {handlePasswordChange}
                margin="normal"
                error={currentPwsError === "" ? false : true}
                helperText={currentPwsError ?? ""}
                fullWidth
                required
              />
              <TextField
                label="New Password"
                variant="outlined"
                type='password'
                name = "newPassword"
                value={newPassword}
                onChange = {handlePasswordChange}
                margin="normal"
                error={newPswError}
                fullWidth
                required
              />
              <TextField
                label="Confirm Password"
                variant="outlined"
                type='password'
                name = "confirmPassword"
                value={confirmPassword}
                onChange = {handlePasswordChange}
                margin="normal"
                error={newPswError}
                fullWidth
                required
              />
              <Button type="submit" variant="contained" color="primary" sx={{mt:2}}>
                Change Password
              </Button>
            </MUIBox>          
          </Collapse>
        </MUIBox>

        </div>
      </div>
      <hr className='mb-4'/>
      <div className='mt-4'>
        <div className='center justify-content-between w-100' style={{paddingLeft: 10}}>
          <b>Unit Measurement</b>
          <div style={{display:'flex'}}>
            <div className='text-center' style={{display:'flex', width:'6vw'}}>
              <div className={'mt-2 '+(unitMeasurement === 'ft' ? 'text-primary fw-bold' : "")}><small>ft</small></div>
              <Radio
                checked={unitMeasurement === 'ft'}
                onChange={handleUnitChange}
                value = 'ft'
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
              />
            </div>

            <div className='text-center' style={{display:'flex', width:'6vw'}}>
              <div className={'mt-2 '+(unitMeasurement === 'm' ? 'text-primary fw-bold' : "")}><small>m</small></div>
              <Radio
                checked={unitMeasurement === 'm'}
                onChange={handleUnitChange}
                value = 'm'
                name="radio-buttons"
                inputProps={{ 'aria-label': 'B' }}
              />
            </div>
          </div>
        </div>
      </div>
      <hr className='my-4'/>
      <h4 className='mb-3'><strong>Appearance</strong></h4>
      <div className='setting-container'>
        <b>Themes</b><br/>
        <div className='center justify-content-between w-100'>
          <div className='text-center' style={{width: '48%'}}>
            {miniCardComponent(LIGHT_DISPLAY_MODE)}
            <div className={'mt-2 ' + (displayMode === LIGHT_DISPLAY_MODE ? 'text-primary fw-bold' : "")}><small>Light</small></div>
            <Radio
              checked={displayMode === LIGHT_DISPLAY_MODE}
              onChange={handleDisplayChange}
              value={LIGHT_DISPLAY_MODE}
              name="radio-buttons"
              inputProps={{ 'aria-label': 'A' }}
            />
          </div>

          <div className='text-center' style={{width: '48%'}}>
            {miniCardComponent(DARK_DISPLAY_MODE)}
            <div className={'mt-2 ' + (displayMode === DARK_DISPLAY_MODE ? 'text-primary fw-bold' : "")}><small>Dark</small></div>
            <Radio
              checked={displayMode === DARK_DISPLAY_MODE}
              onChange={handleDisplayChange}
              value={DARK_DISPLAY_MODE}
              name="radio-buttons"
              inputProps={{ 'aria-label': 'B' }}
            />
          </div>
        </div>
        <hr/>
        <div className='mt-4 center-y'>
          <b>Color Blind Friendly</b><br/>
          <label className="switch me-3">
            <input type="checkbox" onClick={handleColorBlindChange} defaultChecked={colorBlind}/>
            <span className={"slider "+ (colorBlind ? 'color-blind-toggle' : "")}></span>
          </label>
        </div>
      </div>

      <Button
        className='mt-4'
        variant='contained'
        fullWidth
        disabled={!settingChanged}
        onClick={() => {
          window.location.reload()
        }}
      >
        Update Settings
      </Button>
      <small style={{opacity: 0.9}}><i>Basemap and alert icons will update after the change is saved</i></small>
    </div>
  )
}

export default  withApollo(Settings)