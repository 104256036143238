export const SETUP_LOGIN = "SETUP_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_LOADING = "SET_LOADING";
export const SET_TRIP_ACTIVE = "SET_TRIP_ACTIVE";
export const SET_TRIP_PAUSED = "SET_TRIP_PAUSED"
export const SET_EDIT_TRIP_DETAILS = "SET_EDIT_TRIP_DETAILS"
export const SET_START_TRIP_DETAILS = 'SET_START_TRIP_DETAILS'
export const SET_DISPLAY_MODE = 'SET_DISPLAY_MODE'
export const SET_MAP_ALERTS = "SET_MAP_ALERTS"
export const SET_SELECT_ALERT = "SET_SELECT_ALERT"
export const SET_POI_MARKERS = "SET_POI_MARKERS"
export const SET_SELECT_ALERT_CARD = "SET_SELECT_ALERT_CARD"
export const SET_HIDE_MAP_LAYERS = "SET_HIDE_MAP_LAYERS"
export const SET_COLOR_BLIND = "SET_COLOR_BLIND"
export const SET_UNIT_MEASUREMENT = "SET_UNIT_MEASUREMENT"
export const SET_HAS_INTERNET = "SET_HAS_INTERNET"
