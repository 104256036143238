import { withApollo } from '@apollo/client/react/hoc'
import { Button } from '@mui/material'
import _ from "lodash"
import React, { useEffect } from 'react'
import { Detector } from 'react-detect-offline'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { handleCalculateTrip, resetReducerStates, sortAlerts } from '../../Utils/helper'
import { errorToaster } from '../../Utils/messageToast'
import ReturnToCurrentTrip from '../../components/returnToCurrentTrip/ReturnToCurrentTrip'
import RippleGoLogo from "../../images/logos/WhiteRippleGo.png"
import RippleGoDarkLogo from "../../images/logos/logo-dark.png"
import TrabusDarkLogo from "../../images/logos/trabus_logo_short_colored.png"
import TrabusLogo from "../../images/logos/trabus_logo_short_white.png"
import { fetchActiveTrips } from '../../services'
import { setEditTripDetails, setHasInternet, setLoading, setStartTripDetails, setTripActive, setTripPaused } from '../../store/action'

const DARK_DISPLAY_MODE = 'dark'

function Dashboard(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isTripActive = useSelector(state => state.isTripActive)
  const displayMode = useSelector(state => state.displayMode)

  useEffect(() => {
    document.title = 'RippleGo | Dashboard'

    const checkActiveTrip = async () => {
      try {
        const result = await fetchActiveTrips(props)
        if(result.getTrips?.length !== 0 && result.getTrips[0].statuses.at(-1).status !== "END") {
          const tripDetails = _.cloneDeep(result.getTrips[0])
          dispatch(setTripActive(true))
          const calculateTripData = await handleCalculateTrip(props, dispatch, tripDetails.origin, tripDetails.destinations, tripDetails.departureDate, true)
          dispatch(setStartTripDetails({
            ...tripDetails,
            trafficLayer: calculateTripData.trafficLayer
          }))
          dispatch(setTripPaused(tripDetails.statuses.at(-1).status === "PAUSE"))
          sortAlerts(tripDetails, dispatch)
        }
        else {
          resetReducerStates(dispatch)
        }
        dispatch(setLoading(false))
      } catch (error) {
        console.log(error)
      }
    }

    checkActiveTrip()
    dispatch(setEditTripDetails({}))
  },[props, dispatch])

  return (
    <Detector 
      onChange={e => dispatch(setHasInternet(e))}
      render={({online}) => (
        <div className='center flex-column justify-content-between h-100 text-center'>
          <div className='center flex-column' style={{height: '60%'}}>
            <img src={displayMode === DARK_DISPLAY_MODE ? RippleGoLogo : RippleGoDarkLogo} style={{width: '70%'}} alt="RippleGo logo"/>

            <p className='my-5'>
              <b>Welcome to RippleGo</b><br/>
              What do you wish to do today?
            </p>

            {isTripActive && <ReturnToCurrentTrip />}

            <Button 
              fullWidth={true}
              variant="contained"
              onClick={() => {
                if(!online) {
                  errorToaster("Please wait until you have internet connection.")
                  return
                }
                navigate('/manage-trip/add')
              }}
            >
              Add a new trip
            </Button>

            <Button 
              className='mt-3'
              fullWidth={true}
              variant="contained"
              onClick={() => navigate('/planned-trip')}
            >
              open planned trip
            </Button>
          </div>
          <div className='center flex-column'>
            <img src={displayMode === DARK_DISPLAY_MODE ? TrabusLogo : TrabusDarkLogo} style={{width: '60%'}} alt="Trabus logo"/>
            <h6 className="mt-1" style={{fontSize: 12}}>A product of Trabus Technologies</h6>
          </div>
        </div>
      )}
    />
  )
}

export default withApollo(Dashboard)