import { withApollo } from '@apollo/client/react/hoc';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, InputAdornment, TextField, Tooltip } from '@mui/material';
import { loadCss, loadModules } from 'esri-loader';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Detector } from 'react-detect-offline';
import { Disc, Search, WifiOff } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { calculateEtaHoursAndMinutes, ftTom } from '../../Utils/helper';
import { errorToaster } from '../../Utils/messageToast';
import { mileIcons } from '../../components/MarkerIcon/MarkerIcon';
import BetaAgreementForm from '../../components/forms/agreementForm/BetaAgreementForm';
import POIPin from "../../images/POI_icons/poi-pin.svg";
import MSI from "../../images/alert_icons/msi.png";
import OriginPin from "../../images/origin-pin.svg";
import pulsingDot from "../../images/origin-web.webp";
import { getLocationSearch, getMilePath } from '../../services';
import { setHasInternet, setHideMapLayers, setLoading, setPOIMarkers, setSelectedAlert } from '../../store/action';
import MapButtons from './MapButtons';
import "./MapView.scss";

loadCss();

const MAP_ALERT_TYPES = ['bagAlerts', 'lockAlerts', 'msiAlerts', 'riverHeightAlerts']
const EDIT_TRIP_PAGE = '/manage-trip'
const DARK_DISPLAY_MODE = 'dark'
const POI_ACTION_BTN_ID = "remove-poi"
const ALERT_TITLES = {
  'bagAlerts': "Bridge Air Gap Alerts",
  'lockAlerts': "Lock Alerts",
  'msiAlerts': "MSI Alerts",
  'riverHeightAlerts': "River Level Height Alerts"
}

const ALERT_COLORS = {
  INFO: "#0D9A34",
  WARNING: "#FFC000",
  DANGER: "#CD2C2C",
  UNKNOWN: "#808080",
}

const COLORBLIND_ALERT_COLORS = {
  INFO: "#576ffc",
  WARNING: "#ffa947",
  DANGER: "#d10000",
  UNKNOWN: "#808080",
}

const IENC_LAYER = "IENC-layer"
const WATERWAY_LAYER = "waterway-layer"
const WEATHER_LAYER = "weather-layer"
const ORIGIN_LAYER = 'origin-layer'
const DESTINATIONS_LAYER = 'destinations-layer'
const TRAFFIC_LAYER = "traffic-layer"
const CURRENT_POSITION_LAYER = 'current-position-layer'
const BAG_LAYER = 'bagAlerts-layer'
const LOCK_LAYER = 'lockAlerts-layer'
const MSI_LAYER = 'msiAlerts-layer'
const RIVER_LAYER = 'riverAlerts-layer'
const POI_LAYER = 'POI-layer'
const POI_HOVERED_LAYER = "POI-hovered-layer"
const MILE_LAYER = 'mile-layer'

const MAP_ALERT_LAYERS = [BAG_LAYER, LOCK_LAYER, MSI_LAYER, RIVER_LAYER]

function MapView(props) {
  const mapRef = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const currentPath = location.pathname
  const isTripActive = useSelector(state => state.isTripActive)
  const startTripDetails = useSelector(state => state.startTripDetails)
  const editTripDetails = useSelector(state => state.editTripDetails)
  const selectedAlert = useSelector(state => state.selectedAlert)
  const selectedAlertCard = useSelector(state => state.selectedAlertCard)
  const displayMode = useSelector(state => state.displayMode)
  const mapAlerts = useSelector(state => state.mapAlerts)
  const POIMarkers = useSelector(state => state.POIMarkers)
  const hideMapLayers = useSelector(state => state.hideMapLayers)
  const colorBlind = useSelector(state => state.colorBlind)
  const unitMeasurement = useSelector(state => state.unitMeasurement)

  const [mileMarkers, setMileMarkers] = useState([])
  const [mapBtnToggle, setMapBtnToggle] = useState(true)
  const [mapLayers, setMapLayers] = useState(null)
  const [mapData, setMapData] = useState({})
  const [mapBounding, setMapBounding] = useState({
    ne: "",
    sw: ""
  })
  const [POISearchList, setPOISearchList] = useState([{
    id: 0,
    name: "Enter three or more letters"
  }])
  const [hoveredPOI, setHoveredPOI] = useState({})
  const [isPOISelected, setIsPOISelected] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if((!currentPath.includes(EDIT_TRIP_PAGE) || 
      currentPath === "/" ) && isTripActive) {
      setMapData(startTripDetails)

      const getMileMarkers = async () => {
        try {
          const result = await getMilePath(props, {
            tripId: startTripDetails.id
          })
          if(!result.getMilePath) return
          setMileMarkers(result.getMilePath)
        } catch (error) {
          // console.log(error)
        }
      }
      getMileMarkers()
    }
    else {
      setMapData(editTripDetails)
      setMileMarkers([])
    }
  }, [currentPath, editTripDetails, startTripDetails, isTripActive, props])

  useEffect(() => {
    if(isEmpty(selectedAlert)) return
  }, [selectedAlert])

  // Initialize map
  useEffect(() => {
    dispatch(setLoading(true))
    loadModules([
      'esri/Map', 
      'esri/views/MapView', 
      "esri/layers/WMSLayer",
      "esri/layers/GroupLayer",
      "esri/layers/GeoJSONLayer",
      "esri/widgets/Home",
      "esri/geometry/support/webMercatorUtils",
      "esri/core/reactiveUtils",
    ])
    .then(([
      Map, 
      MapView, 
      WMSLayer,
      GroupLayer,
      GeoJSONLayer,
      Home,
      webMercatorUtils,
      reactiveUtils,
    ]) => {
      if(!mapRef.current) return

      const weatherRadarLayer = new WMSLayer({
        id: WEATHER_LAYER,
        title: "Weather Radar",
        url: "https://mapservices.weather.noaa.gov/eventdriven/services/radar/radar_base_reflectivity/MapServer/WMSServer?request=GetCapabilities&service=WMS",
        listMode: "hide-children",
        visible: !hideMapLayers.includes(WEATHER_LAYER)
      })
  
      const IENCLayer = new WMSLayer({
        id: IENC_LAYER,
        title: "IENC",
        url: "https://ienccloud.us/arcgis/services/IENC/USACE_IENC_Master_Service/MapServer/WMSServer?",
        listMode: "hide-children",
        visible: !hideMapLayers.includes(IENC_LAYER)
      })
  
      const waterwayLayer = new GeoJSONLayer({
        id: WATERWAY_LAYER,
        title: "Inland Waterway",
        url: process.env.REACT_APP_INLAND_WATERWAY,
        renderer: {
          type: "simple",
          symbol: {
            type: 'simple-marker',
            style: "circle",
            color: [100,149,237],
            size: 2,
            outline: {
              width: 0
            }
          }
        },
        visible: !hideMapLayers.includes(WATERWAY_LAYER)
      })

      const groupLayers = new GroupLayer({
        title: "Map Layers",
        visible: true,
        visibilityMode: "independent",
        layers: [IENCLayer, waterwayLayer],
        opacity: 0.75
      });

      groupLayers.add(weatherRadarLayer, -1)

      const map = new Map({
        basemap: displayMode === DARK_DISPLAY_MODE ? 'dark-gray-vector' : 'gray-vector',
        layers: [groupLayers]
      });
      

      setMapLayers(groupLayers)
      const view = new MapView({
        container: mapRef.current,
        map: map,
        center: [-91, 36],
        zoom: 5,
      });

      const homeWidget = new Home({ view: view });

      view.ui.move("zoom", "bottom-right");
      view.ui.add(homeWidget, "bottom-right");

      // When Remove button is clicked from the POI marker popup
      reactiveUtils.on(
        () => view.popup,
        "trigger-action",
        (event) => {
          if (event.action.id === POI_ACTION_BTN_ID) {
            let removedPOI = event.action.info.list.filter(point => point.id !== event.action.info.id)
            dispatch(setPOIMarkers(removedPOI))
            if(view.popup.visible) {
              view.popup.close()
            }
          }
        }
      );

      mapRef.current = view

      // set NE and SW bound for POI search
      view.watch('extent', (newExtent) => {
        const ne = webMercatorUtils.xyToLngLat(newExtent.xmax, newExtent.ymax)
        const sw = webMercatorUtils.xyToLngLat(newExtent.xmin, newExtent.ymin)
        setMapBounding({ne, sw})
      })

      // highlight the right-side alert card 
      view.popup.watch("selectedFeature", (feature) => {
        if(feature?.attributes) {
          dispatch(setSelectedAlert(feature.attributes))
        }
        else {
          dispatch(setSelectedAlert({}))
        }
      })
      
      dispatch(setLoading(false))
    })
    .catch(error => {
      console.log(error)
    })
    dispatch(setLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, displayMode])

  const checkIfLayerExists = useCallback((id) => {
    const checkLayer = mapLayers.layers.find(layer => layer.id === id)
    if(checkLayer) {
      mapLayers.layers.remove(checkLayer)
    }
  }, [mapLayers])

  // Origin
  useEffect(() => {
    loadModules([ 'esri/layers/GraphicsLayer', 'esri/PopupTemplate' ])
    .then(([ GraphicsLayer, PopupTemplate ]) => {
      if(!mapRef || !mapLayers) return

      checkIfLayerExists(ORIGIN_LAYER)

      if(isEmpty(mapData.origin)) return

      const originLayer = new GraphicsLayer({ 
        id: ORIGIN_LAYER,
        title: "Origin",
        graphics: {
          geometry: {
            type: 'point',
            longitude: mapData.origin.geo.coordinates[0],
            latitude: mapData.origin.geo.coordinates[1],
          },
          symbol: {
            type: "picture-marker",
            url: OriginPin,
            width: "30px",
            height: "30px"
          },
          popupTemplate: new PopupTemplate({
            title: "Origin",
            content: `
              ${mapData.origin.name}, ${mapData.origin.city}
            `
          })
        }
      })
      mapLayers.add(originLayer)
      
      mapRef.current.goTo({
        center: mapData.origin.geo.coordinates,
        zoom: 6,
        duration: 2000,
        easing: "ease-in-out"
      }).catch(() => {})
    })
  }, [mapData.origin, mapLayers, checkIfLayerExists])

  // Destinations
  useEffect(() => {
    loadModules([ 'esri/layers/GraphicsLayer', 'esri/Graphic', 'esri/PopupTemplate' ])
    .then(([ GraphicsLayer, Graphic, PopupTemplate ]) => {
      if(!mapRef || !mapLayers) return
      
      checkIfLayerExists(DESTINATIONS_LAYER)
      
      if(!mapData.destinations || isEmpty(mapData.destinations)) return
      const destinationsLayer = new GraphicsLayer({title: "Destinations", id: DESTINATIONS_LAYER})
      mapData.destinations.forEach((point) => {
        const destinationPoint = new Graphic({
          geometry: {
            type: "point",
            longitude: point.destination.geo.coordinates[0],
            latitude: point.destination.geo.coordinates[1],
          },
          symbol: {
            type: "picture-marker",
            url: require(`../../images/destination_icons/map-pin-${point.legNumber}${colorBlind ? '-colorblind' : ""}.svg`),
            width: "30px",
            height: "30px"
          },
          popupTemplate: new PopupTemplate({
            title: `Destination ${point.legNumber}`,
            content: `
              ${point.destination.name}, ${point.destination.city}
              ${point.eta ? (<div><br/><br/><b>ETA:</b> ${calculateEtaHoursAndMinutes((isTripActive ? moment.now() : mapData.departureDate), point.eta)}</div>) : ""}
            `
          })
        })
        destinationsLayer.add(destinationPoint)
      })
      mapLayers.add(destinationsLayer)

      mapRef.current.goTo({
        center: mapData.destinations.at(-1).destination.geo.coordinates,
        zoom: 6,
        duration: 2000,
        easing: "ease-in-out"
      }).catch(() => {})
    })

  }, [mapData.destinations, mapData.departureDate, dispatch, mapLayers, checkIfLayerExists, isTripActive, colorBlind])

  // Traffic layer
  useEffect(() =>{
    loadModules([ "esri/layers/GeoJSONLayer" ])
    .then(([GeoJSONLayer]) => {

      if(mapLayers) {
        checkIfLayerExists(TRAFFIC_LAYER)
      }

      if(!mapRef || !mapLayers || !mapData.trafficLayer || isEmpty(mapData.trafficLayer)) return

      const blob = new Blob([JSON.stringify(mapData.trafficLayer)], {
        type: "application/json"
      });
      const trafficLayerUrl = URL.createObjectURL(blob);
      const tripTrafficLayer = new GeoJSONLayer({
        id: TRAFFIC_LAYER,
        title: "Traffic",
        url: trafficLayerUrl,
        renderer: {
          type: 'unique-value',
          field: 'color',
          uniqueValueInfos: [{
            value: "#00ff00",  //green
            symbol: {
              type: 'simple-fill',
              color: colorBlind ? [0, 37, 255, 0.5] : [0, 255, 0, 0.5],
              outline: {
                color: colorBlind ? [28, 134, 235] : [0, 255, 0],
                width: 1.5
              },
            },
          },
          {
            value: "#ffff00",  //yellow
            symbol: {
              type: 'simple-fill',
              color: colorBlind ? [255, 207, 51, 0.5] : [255, 255, 0, 0.5],
              outline: {
                color: colorBlind ? [255, 169, 71] : [255, 255, 0],
                width: 1.5
              },
            },
          },
          {
            value: "#ff0000",  //red
            symbol: {
              type: 'simple-fill',
              color: colorBlind ? [209, 0, 0, 0.5] : [255, 0, 0, 0.5],
              outline: {
                color: colorBlind ? [209, 0, 0] : [255, 0, 0],
                width: 1.5
              },
            },
          }
          ]
        },
      });
      tripTrafficLayer.visible = !hideMapLayers.includes(TRAFFIC_LAYER)
      mapLayers.add(tripTrafficLayer, 1)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData.trafficLayer, dispatch, mapLayers, colorBlind, checkIfLayerExists])

  // Current vessel position
  useEffect(() => {
    loadModules([ 'esri/layers/GraphicsLayer', 'esri/PopupTemplate' ])
    .then(([ GraphicsLayer, PopupTemplate ]) => {
      if(!mapRef || !mapLayers) return

      checkIfLayerExists(CURRENT_POSITION_LAYER)

      if(isEmpty(mapData.currentPosition)) return

      const currentPositionLayer = new GraphicsLayer({ 
        id: CURRENT_POSITION_LAYER,
        title: "Current Position",
        graphics: {
          geometry: {
            type: 'point',
            longitude: mapData.currentPosition.coordinates[0],
            latitude: mapData.currentPosition.coordinates[1],
          },
          symbol: {
            type: "picture-marker",
            url: pulsingDot,
            width: "40px",
            height: "40px"
          },
          popupTemplate: new PopupTemplate({
            title: "Current Vessel Position",
            content: `
              <b>Last updated at: </b> ${mapData.positionUpdatedAt ? `${moment(mapData.positionUpdatedAt).format("YYYY-MM-DD HH:MM")}` : "Data unavailable"}
            `
          })
        }
      })
      mapLayers.add(currentPositionLayer)
    })
  }, [mapData.currentPosition, mapData.positionUpdatedAt, mapLayers, checkIfLayerExists])

  // Alerts
  useEffect(() => {
    loadModules(['esri/layers/GraphicsLayer', 'esri/Graphic', 'esri/PopupTemplate'])
    .then(([GraphicsLayer, Graphic, PopupTemplate]) => {
      if(!mapRef || !mapLayers) return

      if(currentPath.includes('/add')) {
        for(let layer of MAP_ALERT_LAYERS) {
          checkIfLayerExists(layer)
        }
        return
      }

      let i = 0
      for(let alertType of MAP_ALERT_TYPES) {
        const alertLayerID = MAP_ALERT_LAYERS[i]
        i++
        checkIfLayerExists(alertLayerID)

        if(mapAlerts[alertType].length === 0) continue

        const alertLayer = new GraphicsLayer({title: ALERT_TITLES[alertType], id: alertLayerID})
        mapAlerts[alertType].forEach((point) => {
          const timeDiff = moment(calculateEtaHoursAndMinutes(moment.now(), point.eta)).diff(moment.now(), 'days')
          const sevenDaysOut = timeDiff >= 7 && (alertType === 'riverHeightAlerts' || alertType === 'bagAlerts')
          let imgUrl = alertType === 'msiAlerts' ? MSI : require(`../../images/alert_icons/${(colorBlind && point.severity !== "UNKNOWN") ? "colorBlind_" : ""}${alertType}_${point.severity}.png`)
          
          // Change icon if it's 7 days out
          if(sevenDaysOut) {
            imgUrl = require(`../../images/alert_icons/${alertType}_UNAVAILABLE.png`)
          }

          const alertPoint = new Graphic({
            geometry: {
              type: "point",
              longitude: point.location.coordinates[0],
              latitude: point.location.coordinates[1],
            },
            symbol: {
              type: "picture-marker",
              url: imgUrl,
              width: "30px",
              height: "30px"
            },
            attributes: {
              ...point
            },
            popupTemplate: new PopupTemplate({
              title: point.title,
              content: `
                <div style='border-left: 5px solid ${colorBlind ? COLORBLIND_ALERT_COLORS[point.severity] : ALERT_COLORS[point.severity]}; padding: 5px; border-radius: 5px'>
                  ${point.clearanceAtEta ? `<b>Clearance:</b> 
                    ${unitMeasurement === 'ft' ? 
                      point.clearanceAtEta.toFixed(2)+'ft': 
                      ftTom(point.clearanceAtEta).toFixed(2)+'m'}<br/>` : 
                  ""}
                  <b>Message:</b> ${sevenDaysOut ? "Clearance prediction unavailable" : point.message} <br/>
                  <div><b>Mile:</b> ${point.mile}</div>
                  <div style='margin-top: 10px'><b>ETA:</b> ${calculateEtaHoursAndMinutes(moment.now(), point.eta)}</div>
                </div>
              ` 
            })
          })
          alertLayer.add(alertPoint)
        })
        alertLayer.visible = !hideMapLayers.includes(alertLayerID)
        mapLayers.add(alertLayer)
      }

    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapAlerts, mapLayers, checkIfLayerExists, currentPath])

  // Point of Interest
  useEffect(() => {
    loadModules([ 'esri/layers/GraphicsLayer', 'esri/Graphic', 'esri/PopupTemplate' ])
    .then(([ GraphicsLayer, Graphic, PopupTemplate ]) => {
      if(!mapRef || !mapLayers) return

      checkIfLayerExists(POI_LAYER)

      if(POIMarkers.length === 0) return

      const POILayer = new GraphicsLayer({ title: "Point of Interests", id: POI_LAYER})
      POIMarkers.forEach((point, i) => {
        const POIPoint = new Graphic({
          geometry: {
            type: 'point',
            longitude: point.geo.coordinates[0],
            latitude: point.geo.coordinates[1],
          },
          symbol: {
            type: "picture-marker",
            url: require(`../../images/POI_icons/poi-pin-${i+1}.svg`),
            width: "30px",
            height: "30px"
          },
          popupTemplate: new PopupTemplate({
            title: point.name,
            content: `
              <b>Mile:</b> ${point.mile}<br/>
              <b>Waterway:</b> ${point.waterway}
            `,
            actions: [{
              title: "Remove",
              id: POI_ACTION_BTN_ID,
              className: 'esri-icon-close',
              info: {
                ...point,
                list: POIMarkers
              }
            }],
          })
        })
        POILayer.add(POIPoint)
      })
      POILayer.visible = !hideMapLayers.includes(POI_LAYER)
      mapLayers.add(POILayer)
      if(isPOISelected) {
        mapRef.current.popup.open({
          location: POILayer.graphics.items.at(-1).geometry,
          features: [POILayer.graphics.items.at(-1)]
        })
        setIsPOISelected(false)
      }
      mapRef.current.goTo({
        center: POIMarkers.at(-1).geo.coordinates,
        zoom: 6,
        duration: 2000,
        easing: "ease-in-out"
      }).catch(() => {})
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [POIMarkers, mapLayers, checkIfLayerExists])

  // Hovered Point of Interest
  useEffect(() => {
    loadModules([ 'esri/layers/GraphicsLayer', 'esri/PopupTemplate' ])
    .then(([ GraphicsLayer, PopupTemplate ]) => {
      if(!mapRef || !mapLayers) return

      checkIfLayerExists(POI_HOVERED_LAYER)

      if(isEmpty(hoveredPOI)) return

      const HoveredPOILayer = new GraphicsLayer({
        id: POI_HOVERED_LAYER,
        title: "Hovered POI",
        graphics: {
          geometry: {
            type: 'point',
            longitude: hoveredPOI.longitude,
            latitude: hoveredPOI.latitude
          },
          symbol: {
            type: "picture-marker",
            url: POIPin,
            width: "30px",
            height: "30px"
          },
          popupTemplate: new PopupTemplate({
            title: hoveredPOI.name,
            content: `
              <b>Mile:</b> ${hoveredPOI.mile}<br/>
              <b>Waterway:</b> ${hoveredPOI.waterway}
            `
          })
        }
      })
      mapLayers.add(HoveredPOILayer)
      mapRef.current.popup.open({
        location: HoveredPOILayer.graphics.items.at(-1).geometry,
        features: [HoveredPOILayer.graphics.items.at(-1)]
      })
    })
  }, [hoveredPOI, mapLayers, checkIfLayerExists])

  // Mile Markers
  useEffect(() => {
    loadModules(['esri/layers/GraphicsLayer', 'esri/Graphic',])
    .then(([GraphicsLayer, Graphic]) => {
      if(mapLayers) {
        checkIfLayerExists(MILE_LAYER)
      }

      if(!mapRef || !mapLayers || mileMarkers.length === 0) return

      const mileMarkerLayer = new GraphicsLayer({title: "Mile Markers", id: MILE_LAYER})
      mileMarkers.forEach((point) => {
        const mileIcon = mileIcons(point.mile)
        const mileMarkerPoint = new Graphic({
          geometry: {
            type: 'point',
            longitude: point.long,
            latitude: point.lat
          },
          symbol: {
            type: "picture-marker",
            url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(mileIcon)}`,
            width: "30px",
            height: "30px"
          }
        })
        mileMarkerLayer.add(mileMarkerPoint)
      })
      mileMarkerLayer.visible = !hideMapLayers.includes(MILE_LAYER)
      mapLayers.add(mileMarkerLayer)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mileMarkers, mapLayers, checkIfLayerExists])

  // If right side alert card is selected, then display tis popup on the map
  useEffect(() => {
    if(!mapRef || !mapLayers) return

    let alertMarker = null

    for(const layer of mapLayers.layers) {
      if(layer.id.includes('Alerts')) {
        let findAlert = layer.graphics.items.filter( d => d.attributes.id === selectedAlertCard.id)
        if(findAlert.length > 0) {
          alertMarker = findAlert[0]
          break;
        }
      }
    }

    if(alertMarker !== null) {
      mapRef.current.popup.open({
        location: alertMarker.geometry,
        features: [alertMarker]
      })
    }

  }, [selectedAlertCard, mapLayers])

  useEffect(() => {
    if(!mapLayers) return

    for(let layer of mapLayers.layers) {
      layer.visible = !hideMapLayers.includes(layer.id)
    }
  }, [mapLayers, hideMapLayers])

  // Check if POI Layer toggle is on or off
  const checkPOILayer = () => {
    setIsPOISelected(true)
    if(hideMapLayers.indexOf(POI_LAYER) === -1) return

    let index = hideMapLayers.indexOf(POI_LAYER)
    hideMapLayers.splice(index,1)
    dispatch(setHideMapLayers(hideMapLayers))
  }

  const centerToCurrentPosition = () => {
    if(!mapRef || !mapData.currentPosition) return
    
    mapRef.current.goTo({
      center: mapData.currentPosition.coordinates,
      zoom: 9,
      duration: 2000,
      easing: 'ease-in-out'
    }).catch(() => {})
  }
  
  const locationSearch = async text => {
    setPOISearchList([])
    if(text.length < 3) return

    try {
      const result = await getLocationSearch(props, { 
        text,
        neCorner: `POINT(${mapBounding.ne[0]} ${mapBounding.ne[1]})`,
        swCorner: `POINT(${mapBounding.sw[0]} ${mapBounding.sw[1]})`,
      })
      setPOISearchList(result.getLocations)
    } catch (error) {
      console.log(error)
      errorToaster("Unable to find the locations, please try again later.")
    }
  }

  return (
    <Detector 
      onChange={e => dispatch(setHasInternet(e))}
      render={({online}) => (
        <div style={{position: 'relative'}}>
          <div className={'beta-banner ' + displayMode}>RippleGo BETA test version. View <span className='beta-agreement-text' onClick={() => setModalOpen(true)}>BETA TEST AGREEMENT</span></div>
          <BetaAgreementForm 
            modalOpen={modalOpen}
            modalOnClose={() => setModalOpen(false)}
          />
          <div
            ref={mapRef}
            style={{
              width: "100%",
              height: "calc(100vh - 25px)"  // 100vh when there's no beta banner
            }}
          />
          { (isTripActive && !isEmpty(mapData.currentPosition)) &&
            <div className='current-position' onClick={centerToCurrentPosition}>
              <Disc size={20} color='#919191'/>
            </div>
          }
          <div className={'poi ' + displayMode}>
            <Autocomplete
              options={POISearchList}
              fullWidth
              getOptionLabel={(option) => option.name}
              getOptionDisabled={(option) => option.id === 0}
              onChange={(_, val) => {
                if(!val) return
                dispatch(setPOIMarkers([...POIMarkers, val]))
                checkPOILayer()
                setHoveredPOI({})
              }}
              onInputChange={(_,val) => {
                locationSearch(val)
                setHoveredPOI({})
              }}
              onHighlightChange={(e, val) => {
                if(!e) {
                  setHoveredPOI({})
                  return
                }
                setHoveredPOI(val)
              }}
              renderInput={(params) => 
                <TextField 
                  {...params} 
                  label="Fuel / Repair / (River, Mile) ..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: 
                      <InputAdornment position='end'>
                        <Search color="#008f8f"  />
                      </InputAdornment>
                  }}
                />
              }
            />
          </div>
          {!online &&
            <div className='offline'>
              <Tooltip arrow title="Network is currently offline" >
                <WifiOff color="#CD2C2C" size={29} />
              </Tooltip>
            </div>
          }
          <div className={'map-btn-toggle ' + (displayMode)} onClick={() => setMapBtnToggle(!mapBtnToggle)}>
            <FontAwesomeIcon icon={faLayerGroup}/>
          </div>
          <div className={'map-btn shadow ' + (mapBtnToggle ? 'show ' : "") + (displayMode)}>
            <MapButtons />
          </div>
        </div>
      )}
    />
  )
}

export default withApollo(MapView)