import { reactLocalStorage } from "reactjs-localstorage";
import {
  SETUP_LOGIN,
  SET_COLOR_BLIND,
  SET_DISPLAY_MODE,
  SET_EDIT_TRIP_DETAILS,
  SET_HAS_INTERNET,
  SET_HIDE_MAP_LAYERS,
  SET_LOADING,
  SET_LOGOUT,
  SET_MAP_ALERTS,
  SET_POI_MARKERS,
  SET_SELECT_ALERT,
  SET_SELECT_ALERT_CARD,
  SET_START_TRIP_DETAILS,
  SET_TRIP_ACTIVE,
  SET_TRIP_PAUSED,
  SET_UNIT_MEASUREMENT
} from "./types";

const INITIAL_STATE = {
  isLogin: false,
  user: null,
  editTripDetails: {},
  startTripDetails: {},
  loading: false,
  displayMode: 'light',
  colorBlind: false,
  hasInternet: true,
  mapAlerts: {
    bagAlerts: [],
    lockAlerts: [],
    msiAlerts: [],
    riverHeightAlerts: []
  },
  isTripActive: false,
  isTripPaused: false,
  selectedAlert: {},
  selectedAlertCard: {},
  POIMarkers: [],
  hideMapLayers: [
    "IENC-layer",
    "waterway-layer",
    "weather-layer",
    "POI-layer",
    "mile-layer",
    "traffic-layer"
  ],
  unitMeasurement: 'ft'
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETUP_LOGIN:
      reactLocalStorage.set("token", action.payload.token);
      reactLocalStorage.set("refreshToken", action.payload.refreshToken);
      return {
        ...state,
        isLogin: true,
        user: action.payload.user,
      };
    case SET_LOGOUT:
      reactLocalStorage.clear("token");
      reactLocalStorage.clear("refreshToken");
      return {
        ...state,
        isLogin: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SET_TRIP_PAUSED:
      return {
        ...state,
        isTripPaused: action.payload
      }
    case SET_TRIP_ACTIVE:
      return {
        ...state,
        isTripActive: action.payload
      }
    case SET_EDIT_TRIP_DETAILS:
      return {
        ...state,
        editTripDetails: action.payload,
      };
    case SET_START_TRIP_DETAILS:
      return {
        ...state,
        startTripDetails: action.payload,
      };
    case SET_DISPLAY_MODE:
      return {
        ...state,
        displayMode: action.payload,
      };
    case SET_MAP_ALERTS:
      return {
        ...state,
        mapAlerts: action.payload
      }
    case SET_SELECT_ALERT:
      return {
        ...state,
        selectedAlert: action.payload
      }
    case SET_SELECT_ALERT_CARD:
      return {
        ...state,
        selectedAlertCard: action.payload
      }
    case SET_POI_MARKERS:
      return {
        ...state,
        POIMarkers: action.payload
      }
    case SET_HIDE_MAP_LAYERS:
      return {
        ...state,
        hideMapLayers: action.payload
      }
    case SET_COLOR_BLIND:
      return {
        ...state,
        colorBlind: action.payload
      }
    case SET_UNIT_MEASUREMENT:
      return {
        ...state,
        unitMeasurement: action.payload
      }
    case SET_HAS_INTERNET:
      return {
        ...state,
        hasInternet: action.payload
      }
    default:
      return state;
  }
};
export default reducer;
