import { reactLocalStorage } from "reactjs-localstorage";
import { logoutSession, setLoading, setMapAlerts, setPOIMarkers, setSelectedAlert, setSelectedAlertCard, setStartTripDetails, setTripActive, setTripPaused } from "../store/action";
import history from "./history";
import { store } from "../store";
import { errorToaster, successToaster } from "./messageToast";
import moment from "moment";
import { isEmpty } from "lodash";
import { calculateTrip, createTripStatus, getTraffic } from "../services";

export const logout = (props) => {
  if (props) {
    props.dispatch(logoutSession());
  } else {
    store.dispatch(logoutSession());
  }
  store.dispatch({
    type: "RESET",
  });
  reactLocalStorage.remove("token");
  reactLocalStorage.remove("refreshToken");
  reactLocalStorage.clear();
  localStorage.setItem("persist:root", null);
  history.push("/login");
};

export const handleCalculateTrip = async (props, dispatch, origin, destinations, departureDate, isTripActive) => {
  if(isEmpty(origin)) return { trafficLayer: {}, eta: null}
  if(destinations.length === 0) return { trafficLayer: {}, eta: null}
  
  dispatch(setLoading(true))

  let sortedDestinations = destinations.sort((a,b) => a.legNumber - b.legNumber)

  let destinationIDs = sortedDestinations.map(d => {
    return d.destination.id
  })

  let mapTraffic = {
    type: "FeatureCollection",
    features: []
  }
  let eta = null

  try {
    const result = await calculateTrip(props, {
      origin: origin.id,
      destinations: destinationIDs,
      departureDate: new Date(departureDate)
    })

    const addEtaTime = isTripActive ? moment.now() : departureDate
    eta = calculateEtaHoursAndMinutes(addEtaTime, Math.round(result.calculateTrip.eta))

    let source = {
      id: "traffic_layer",
      layerName:'Polyline',
      geojson: {
        type: "FeatureCollection",
        features: [],
      },
      fillstyle: {
        id: "traffic-fill-layer",
        type: "fill",
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.3,
        },
      },
      style: {
        id: "traffic-outline-layer",
        type: "line",
        paint: {
          "line-color": ["get", "color"],
          "line-width": 2,
        },
      },
    }

    try {
      const result = await getTraffic(props, {
        origin: `POINT(${origin.geo.coordinates[0]} ${origin.geo.coordinates[1]})`,
        destinations: destinationIDs
      })

      let urls = []
      for(let route of result.getTraffic) {
        urls.push(`${process.env.REACT_APP_JSON_CDN}${route.id}.json`)
      }
  
      await Promise.all(urls.map (url => 
        fetch(url)
        .then(response => response.json())
      ))
      .then(data => {

        // Update the color for each channel reach
        for(let index in result.getTraffic) {
          data[index].properties.color = result.getTraffic[index].color
          source.geojson.features = data
          mapTraffic.features.push(data[index])
        }
      })
      .catch(error => console.log(error))
    } catch (error) {
      errorToaster("Unable to fetch traffic at the moment. Please try again later.")
    }
  } catch (error) {
    console.log(error)
    eta = null
    mapTraffic = {}
    errorToaster("Route is invalid, cannot go upstream and downstream on the same river.")
  }

  dispatch(setLoading(false))
  return { trafficLayer: mapTraffic, eta}
}

export const sortDestinations = destinations => {
  let sortedDest = [...destinations]
  return sortedDest.sort((a, b) => a.legNumber - b.legNumber)
}

export const sortAlerts = (tripDetails, dispatch) => {
  const BRIDGE_ALERT_TYPE = "bridge"
  const LOCK_ALERT_TYPE = "lock"
  const MSI_ALERT_TYPE = "msi"
  const RIVER_ALERT_TYPE = "river"

  let alerts = []
  let bagAlerts = tripDetails.bagAlerts.map( d => {return {...d, type: BRIDGE_ALERT_TYPE}})
  let lockAlerts = tripDetails.lockAlerts.map( d => {return {...d, type: LOCK_ALERT_TYPE}})
  let msiAlerts = tripDetails.msiAlerts.map( d => {return {...d, type: MSI_ALERT_TYPE}})
  let riverHeightAlerts = tripDetails.riverHeightAlerts.map( d => {return {...d, type: RIVER_ALERT_TYPE}})

  alerts = alerts.concat(bagAlerts, lockAlerts, msiAlerts, riverHeightAlerts)
  alerts.sort((a,b) => a.sortOrder - b.sortOrder)

  dispatch(setMapAlerts({
    bagAlerts,
    lockAlerts,
    msiAlerts,
    riverHeightAlerts
  }))

  return alerts
}

export const calculateEtaHoursAndMinutes = (newDate, eta) => {
  let currentDate = moment(newDate).add(eta, "minutes").toDate();
  return `${moment(currentDate).format("MMM DD, YYYY HH:mm")}`;
};


export const errorHandler = (error) => {
  try {
    console.log(error);
    if (error.graphQLErrors[0].code === 400) {
      let details = error.graphQLErrors[0].details;
      let errors = [];
      for (const key in details) {
        errors = errors.concat(details[key]);
      }
      errorToaster(errors[0]);
      console.log('error1: ',errors[0])
    }
    if (error.graphQLErrors[0].code === 500) {
      let details = error.graphQLErrors[0].details;
      let errors = [];
      if (typeof details === "string") {
        console.log('error2: ',details)
        return errorToaster(details);
      }
      for (const key in details) {
        errors = errors.concat(details[key]);
      }
      errorToaster(errors[0]);
      console.log('error3: ',errors[0])
    }
  } catch (error){
    console.log(error)
  }
};

export const downloadCSV = (CSVData, origin, destinations) => {
  let updatedCSVData = []
  let locationHeader = ['Location', 'Name']
  let alertHeader = []

  // remove __typename and re-format date time
  for(let data of CSVData) {
    let row = []
    for(let [key,val] of Object.entries(data)) {
      if(key === "__typename") continue
      if(key === 'datetime' && val !== "-") {
        val = moment(val).format('YYYY/MM/DD  HH:mmZ')
      }
      if(key === 'description' && val.includes('RESUME')) {
        val = 'RESUME'
      }
      if(key === 'description' && val.includes('END')) {
        val = 'END'
      }

      if(!alertHeader.includes(csvHeaderNames(key))) {
        alertHeader.push(csvHeaderNames(key))
      }
      row.push(val)
    }
    updatedCSVData.push(row)
  }

  // add origin and destinations
  if(isEmpty(origin) || destinations.length === 0) return updatedCSVData

  let locationCSVData = []
  destinations = destinations.reverse()
  
  for(let i in destinations) {
    locationCSVData.unshift([`DESTINATION ${(parseInt(destinations.length - i))}`, `${destinations[i].destination.name.toUpperCase()}, ${destinations[i].destination.city?.toUpperCase() ?? ""}`])
  }
  locationCSVData.unshift(["ORIGIN", `${origin.name.toUpperCase()}, ${origin.city?.toUpperCase() ?? ""}`])

  return {locationHeader, alertHeader, data: updatedCSVData, locationData: locationCSVData}
}

const csvHeaderNames = (name) => {
  switch(name) {
    case "datetime":
      return "Datetime"
    case "lat":
      return "Latitude"
    case "long":
      return "Longitude"
    case "eta":
      return "ETA (minutes)"
    case "alertType":
      return "Alert Type"
    default: 
      return name.charAt(0).toUpperCase() + name.slice(1);
  }
}

// For PAUSE and RESUME
export const handleTripStatus = async (props, dispatch, navigate, status, reason, tripId) => {
  try {
    const result = await createTripStatus(props, {
      status,
      reason,
      tripId
    })

    if(!result?.createStatus) {
      errorToaster("Unable to update trip status at the moment. Please try again later.")
      return
    }

    dispatch(setTripPaused(status === 'PAUSE'))
    
    successToaster("Trip status updated successfully!")
    navigate(`/start-trip/${tripId}`)
    
  } catch (error) {
    console.log(error)
  }
}

export const ftTom = (num) => {
  return num*0.3048
}

export const resetReducerStates = (dispatch) => {
  dispatch(setTripActive(false))
  dispatch(setTripPaused(false))
  dispatch(setStartTripDetails({}))
  dispatch(setSelectedAlert({}))
  dispatch(setSelectedAlertCard({}))
  dispatch(setPOIMarkers([]))
  dispatch(setMapAlerts({
    bagAlerts: [],
    lockAlerts: [],
    msiAlerts: [],
    riverHeightAlerts: []
  }))
}
