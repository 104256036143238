import Highcharts from 'highcharts';
import { useSelector } from 'react-redux'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment';

import React from 'react'
import { calculateEtaHoursAndMinutes, ftTom } from '../../Utils/helper';
import _ from 'lodash';

const DARK_DISPLAY_MODE = 'dark'
const CHART_COLORS = ["#14c1e3", "#b615c2", "#e66101", "#e66101", ]

export default function Chart({data}) {
  const unitMeasurement = useSelector(state => state.unitMeasurement)
  const displayMode = useSelector(state => state.displayMode)

  let etaIndex = 0
  let eta = calculateEtaHoursAndMinutes(moment.now(), data.eta)
  for(let time of data.dt) {
    const dateIsAfter = moment(time).isAfter(eta)
    etaIndex++
    if(dateIsAfter) break;
  }

  let series = []
  let dataInUnit = []
  let index = 0
  if(data.predictions) {
    let BAGdata = JSON.parse(data.predictions)
    for(let [key,val] of Object.entries(BAGdata)) {
      if(key === "main_span") continue
      
      let label = key.trim()
      label = _.capitalize(label)
      if(label === "Main") label = "Bridge Air Gap"

      for(let i = 0; i < val.length; i++){
        if(unitMeasurement === 'ft'){
          dataInUnit.push(parseFloat(val[i]))
        } else{
          dataInUnit.push(parseFloat(ftTom(val[i])))
        }
      }

      series.unshift({
        name: label,
        data: dataInUnit,
        color: CHART_COLORS[index]
      })
      dataInUnit = []
      index++
    }
  }
  else {
    for(let i = 0; i < (data.clearance).length; i++){
      if(unitMeasurement === 'ft'){
        dataInUnit.push(parseFloat((data.clearance)[i]))
      } else{
        dataInUnit.push(parseFloat(ftTom((data.clearance)[i])))
      }
    }
    series.push({
      data: dataInUnit,
      name: "River Level",
      color: "#14c1e3"
    })
  }

  let option = {
    chart: {
      type: 'areaspline',
      backgroundColor: 'transparent',
      height: 300
    },
    title: {
      text: ""
    },
    xAxis: {
      categories: data.dt,
      labels: {
        formatter: function() {
          return moment(this.value).format("MMM DD")
        },
        style: {
          color: displayMode === DARK_DISPLAY_MODE? "white" : "black"
        }
      },
      tickInterval: Math.ceil(data.dt.length / 4),
      plotLines: [{
        color: 'red',
        width: 2,
        value: etaIndex,
        zIndex: 10,
        label: {
          text: "ETA",
          style: {
            color: displayMode === DARK_DISPLAY_MODE? "white" : "black"
          }
        }
      }],
    },
    yAxis: {
      title: {
        text: `${unitMeasurement === 'ft' ? 'Clearance (ft)' : 'Clearance (m)'}`,
        style: {
          color: displayMode === DARK_DISPLAY_MODE? "white" : "black"
        }
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        let name = this.series.name.toUpperCase()
        name = name.trim()
        return (`
          <div><b>${name}</b></div> <br/>
          <div><b>${moment(this.x).format("MMM DD, YYYY HH:mm")}</b></div>
          Clearance: ${this.y.toFixed(2)} ${unitMeasurement === 'ft' ? 'ft': 'm'}
        `)
      } 
    },
    series,
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        color: displayMode === DARK_DISPLAY_MODE? "white" : "black"
      }
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={option}
      />
    </div>
  )
}
