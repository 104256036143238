import { withApollo } from '@apollo/client/react/hoc'
import { faBell, faBridgeWater, faCloudSunRain, faDroplet, faHashtag, faLock, faSignsPost, faWater, faShip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mui/material'
import React, { useRef } from 'react'
import { MapPin } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setHideMapLayers } from '../../store/action'

const IENC_LAYER = "IENC-layer"
const WATERWAY_LAYER = "waterway-layer"
const WEATHER_LAYER = "weather-layer"
const BAG_LAYER = 'bagAlerts-layer'
const LOCK_LAYER = 'lockAlerts-layer'
const MSI_LAYER = 'msiAlerts-layer'
const RIVER_LAYER = 'riverAlerts-layer'
const POI_LAYER = 'POI-layer'
const MILE_LAYER = 'mile-layer'
const TRAFFIC_LAYER = "traffic-layer"

function MapButtons() {
  const divRef = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const currentPath = location.pathname

  const hideMapLayers = useSelector(state => state.hideMapLayers)
  const isTripActive = useSelector(state => state.isTripActive)
  const colorBlind = useSelector(state => state.colorBlind)

  const hideShowLayer = id => {
    let updatedHideMapLayers = [...hideMapLayers]
    if(updatedHideMapLayers.includes(id)) {
      let index = updatedHideMapLayers.indexOf(id)
      updatedHideMapLayers.splice(index, 1)
    }
    else {
      updatedHideMapLayers.push(id)
    }
    dispatch(setHideMapLayers(updatedHideMapLayers))
  }

  const enableAlertMapBtn = () => {
    if(!isTripActive) return true
    if(currentPath.includes('/add') || currentPath.includes('/edit-planned')) {
      return true
    }

    return false
  }

  return (
    <div ref={divRef}>
      <small className='map-layer-dropdown-label'>Alerts</small>
      <div className={'map-layers-alert-div ' + (enableAlertMapBtn() ? "disabled" : "")}>
        <Tooltip arrow title="Under keel clearance alert" placement='right'>
          <div className={'map-layer-btn-div ' + (hideMapLayers.includes(RIVER_LAYER) ? "hide " : "")} onClick={() => hideShowLayer(RIVER_LAYER)}>
            <FontAwesomeIcon className={'map-layer-btn-icon '+ (colorBlind ? 'color-blind-map-layer-btn' : "")} icon={faWater}/>
            <small>River Level Height</small>
          </div>
        </Tooltip>
        <Tooltip arrow title="Top of boat clearance" placement='right'>
          <div className={'map-layer-btn-div ' + (hideMapLayers.includes(BAG_LAYER) ? "hide " : "")} onClick={() => hideShowLayer(BAG_LAYER)}>
            <FontAwesomeIcon className={'map-layer-btn-icon '+ (colorBlind ? 'color-blind-map-layer-btn' : "")} icon={faBridgeWater}/>
            <small>Bridge Air Gap</small>
          </div>
        </Tooltip>
        <Tooltip arrow title="Lock and dam status" placement='right'>
          <div className={'map-layer-btn-div ' + (hideMapLayers.includes(LOCK_LAYER) ? "hide " : "")} onClick={() => hideShowLayer(LOCK_LAYER)}>
            <FontAwesomeIcon className={'map-layer-btn-icon '+ (colorBlind ? 'color-blind-map-layer-btn' : "")} icon={faLock}/>
            <small>Lock</small>
          </div>
        </Tooltip>
        <Tooltip arrow title="Navigational Alert" placement='right'>
          <div className={'map-layer-btn-div ' + (hideMapLayers.includes(MSI_LAYER) ? "hide " : "")} onClick={() => hideShowLayer(MSI_LAYER)}>
            <FontAwesomeIcon className={'map-layer-btn-icon msi'} icon={faBell}/>
            <small>Nav. Alert</small>
          </div>
        </Tooltip>
      </div>

      <div className='px-3'><hr/></div>
      
      <small className='map-layer-dropdown-label'>Featured Layers</small>
      <div className={'map-layer-btn-div ' + (hideMapLayers.includes(MILE_LAYER) ? "hide " : "") + (enableAlertMapBtn() ? " disabled" : "")} onClick={() => hideShowLayer(MILE_LAYER)}>
        <FontAwesomeIcon className='map-layer-btn-icon mile' icon={faHashtag}/>
        <small>Mile Markers</small>
      </div>
      <div className={'map-layer-btn-div ' + (hideMapLayers.includes(TRAFFIC_LAYER) ? "hide " : "")} onClick={() => hideShowLayer(TRAFFIC_LAYER)}>
        <FontAwesomeIcon className='map-layer-btn-icon' icon={faShip}/>
        <small>Traffic</small>
      </div>
      <div className={'map-layer-btn-div ' + (hideMapLayers.includes(POI_LAYER) ? "hide " : "")} onClick={() => hideShowLayer(POI_LAYER)}>
        <MapPin className='map-layer-btn-icon-map-pin' color='white' size="20px"/>
        <small>Point of Interests</small>
      </div>
      <div className={'map-layer-btn-div ' + (hideMapLayers.includes(WATERWAY_LAYER) ? "hide " : "")} onClick={() => hideShowLayer(WATERWAY_LAYER)}>
        <FontAwesomeIcon className={'map-layer-btn-icon '+(colorBlind ? 'color-blind-map-layer-btn' : "")} icon={faDroplet}/>
        <small>Inland Waterway</small>
      </div>
      <div className={'map-layer-btn-div ' + (hideMapLayers.includes(WEATHER_LAYER) ? "hide " : "")} onClick={() => hideShowLayer(WEATHER_LAYER)}>
        <FontAwesomeIcon className={'map-layer-btn-icon '+(colorBlind ? 'color-blind-map-layer-btn' : "")} icon={faCloudSunRain}/>
        <small>Weather Radar</small>
      </div>
      <Tooltip arrow title="Inland Electronic Navigational Charts" placement='right'>
        <div 
          className={'map-layer-btn-div ' + (hideMapLayers.includes(IENC_LAYER) ? "hide " : "")} onClick={() => hideShowLayer(IENC_LAYER)}>
          <FontAwesomeIcon className={'map-layer-btn-icon '+(colorBlind ? 'color-blind-map-layer-btn' : "")} icon={faSignsPost}/>
          <small>IENC</small>
        </div>
      </Tooltip>

      <div className='px-3'><hr/></div>
      <small className='map-layer-dropdown-label'>Alert Icon Legends</small>
      <div className='map-legend mt-1'>
        <div className={'map-legend-color green center ' + (colorBlind ? 'color-blind' : "")}> - </div><small> - Clear</small>
      </div>
      <div className='map-legend'>
        <div className={'map-legend-color yellow center ' + (colorBlind ? 'color-blind' : "")}> - </div><small> - Warning</small>
      </div>
      <div className='map-legend'>
        <div className={'map-legend-color red center ' + (colorBlind ? 'color-blind' : "")}> - </div><small> - Danger</small>
      </div>
      <div className='map-legend'>
        <div className={'map-legend-color unknown center ' + (colorBlind ? 'color-blind' : "")}> - </div><small> - No data</small>
      </div>
      <div className='map-legend'>
        <div className={'map-legend-color white center ' + (colorBlind ? 'color-blind' : "")}> - </div><small> - Unavailable</small>
      </div>
    </div>
  )
}

export default withApollo(MapButtons)