import { withApollo } from '@apollo/client/react/hoc';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, List, ListItemButton, ListItemText, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Info } from 'react-feather';
import { errorToaster } from '../../Utils/messageToast';
import { GetSearchResult } from '../../services';
import { useSelector } from 'react-redux';

const ORIGIN_FIELD = 'ORIGIN'

function LocationSearch({
	handleSearchLocationOnClick, 
	removeDestination,
	clearErrors,
	type, 
	destNum, 
	locationName,
	disabled,
  onChange,
	...props
}) {
  const displayMode = useSelector(state => state.displayMode)

	const [locationList, setLocationList] = useState([])
	const [searchLocationName, setSearchLocationName] = useState("")

  useEffect(() => {
    if(locationName) {
      let city = locationName.city ? `, ${locationName.city.toUpperCase()}` : ""
      setSearchLocationName(`${locationName.name} ${city}`)
    }
  }, [locationName])

	const searchLocation = async text => {
		setSearchLocationName(text)
    if(text.length < 3) {
			if(text.length > 0 ) {
				setLocationList([{name: "Please enter more than three letters"}])	
			}
			else {
				setLocationList([])	
			}
			return
		}

    setLocationList([{name: "Loading..."}])

    try {
      let result = await GetSearchResult(props, {text})
      if(result.getLocations?.length > 0) {
        setLocationList(result.getLocations)
      } 
      else {
        setLocationList([{name: "Location name not found"}])
      }
    } catch (error) {
      errorToaster("Unable to search the location at the moment. Please try again later.")
    }
	}

	const selectLocation = location => {
		setLocationList([])
		setSearchLocationName(location.name)
		handleSearchLocationOnClick(location, type, destNum-1)
		clearErrors(type.toLowerCase())
	}

  return (
    <div className='trip-modal-destinations'>
      <TextField
        {...props}
        className='trip-modal-text-field'
        label={type === ORIGIN_FIELD ? "Origin" : ("Destination " + destNum)}
        variant="standard"
        autoComplete='off'
        fullWidth={true}
        value={searchLocationName !== "" ? searchLocationName : ""}
        onChange={e => {onChange(e); searchLocation(e.target.value)}}
        error={props.error}
        helperText={props.helperText}
        disabled={disabled}
        InputProps={{
          endAdornment: 
          <InputAdornment position="end">
            <Tooltip title={<>Want to search for mile markers? <br/>Type (river name, mile) with parentheses. <br/> E.g. (ohio, 500)</>}>
              <Info 
                color="#008f8f" 
                size={22}
                style={{cursor: "help"}}
              />
            </Tooltip>
            {!disabled && type !== ORIGIN_FIELD && destNum !== 1 &&
              <FontAwesomeIcon className='ms-2 me-1 pointer' icon={faX} size="sm" color="#008F85" onClick={() => removeDestination(destNum - 1)}/>
            }
            </InputAdornment>
        }}
      />
      {locationList.length > 0 &&
        <List className={'trip-modal-list ' + displayMode} disablePadding={true}>
          {locationList.map((location, key) => (
            <ListItemButton className='border-bottom' key={key} onClick={() => selectLocation(location)} disabled={location.name === "Location name not found"}>
              <ListItemText
                  primary={`${location.name} ${location.city ? `, ${location.city.toUpperCase()}` :  ""}`}
              />
            </ListItemButton>
          ))}
        </List>
      }
     
    </div>
  )
}

export default withApollo(LocationSearch)