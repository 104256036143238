import { withApollo } from '@apollo/client/react/hoc';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Button, InputAdornment, TextField, Tooltip } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Detector } from 'react-detect-offline';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { downloadCSV, handleCalculateTrip, sortDestinations, ftTom } from '../../Utils/helper';
import { errorToaster, successToaster, warningToaster } from '../../Utils/messageToast';
import ReturnToCurrentTrip from '../../components/returnToCurrentTrip/ReturnToCurrentTrip';
import { addTrip, editTrip, fetchGetvessels, getCSVPrevoyage, startTrip } from '../../services';
import { setEditTripDetails, setHasInternet, setLoading, setMapAlerts, setStartTripDetails, setTripActive } from '../../store/action';
import "./AddEditTrip.scss";
import DraggableInputs from './DraggableInputs';
import EndTripComfirm from './EndTripComfirm';
import LocationSearch from './LocationSearch';
import PauseTrip from './PauseTrip';

const ORIGIN_FIELD = 'ORIGIN'
const DESTINATION_FIELD = 'DESTINATIONS'
const ADD_PAGE = 'add'
const EDIT_ACTIVE_PAGE = 'edit-active'
const EDIT_PLANNED_PAGE = 'edit-planned'
const DEFAULT_VESSEL_STATE = 'AVAILABLE'
const PAUSE = "PAUSE"
const END = "END"
const DEFAULT_REASON = "None"
const PRIMARY_COLOR = "#008F85"

function AddEditTrip(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const formRef = useRef(null)
  const CSVRef = useRef(null)
  const currentPath = location.pathname.split('/')[2].split(":")[0]
  const isTripActive = useSelector(state => state.isTripActive)
  const isTripPaused = useSelector(state => state.isTripPaused)
  const editTripDetails = useSelector(state => state.editTripDetails)
  const startTripDetails = useSelector(state => state.startTripDetails)
  const unitMeasurement = useSelector(state => state.unitMeasurement)
  const hasInternet = useSelector(state => state.hasInternet)

  const [destinationsData, setDestinations] = useState([])
  const [vesselList, setVesselList] = useState([])
  const [showEta, setShowEta] = useState(null)
  const [tripInfo, setTripInfo] = useState({})
  const [disabled, setDisabled] = useState(false)  // Disable some input fields for editing active trip
  const [tripSaved, setTripSaved] = useState(true)
  const [tripCreated, setTripCreated] = useState(false)
  const [tripId, setTripId] = useState("")
  const [trafficLayer, setTrafficLayer] = useState({})
  const [vesselState, setVesselState] = useState(DEFAULT_VESSEL_STATE)
  const [tripStatus, setTripStatus] = useState("")
  const [reason, setReason] = useState(DEFAULT_REASON)
  const [prevoyageData, setPrevoyageData] = useState({})
  const [disablePrevoyage, setDisablePrevoyage] = useState(true) // Disable prevoyage button if departure date didn't meet the condition
  const [isCorrectInputs, setIsCorrectInputs] = useState(true)  // If there's invalid input, disable the buttons
  const [startTripClicked, setStartTripClicked] = useState(false)  // make sure user won't click twice

  const {
    register,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    formState: {errors}
  } = useForm({mode: 'onChange'})

  useEffect(() => {
    if(!hasInternet && currentPath.includes(ADD_PAGE)) {
      navigate("/")
    }
  }, [hasInternet, navigate, currentPath])

  // Get Traffic layer and ETA
  const getCalculateTripData = useCallback(async (origin, dests, dDate) => {
    let originData = getValues('origin') ?? origin
    let getDestinations = getValues('destinations') ?? dests
    let departureDate = getValues('departureDate') ?? dDate
    
    if(isTripActive) {
      departureDate = new Date()
    }

    setTripSaved(false)
  
    try {
      const calculateData = await handleCalculateTrip(props, dispatch, originData, getDestinations, departureDate)
      setShowEta(calculateData.eta)
      setTrafficLayer(calculateData.trafficLayer)
    } catch (error) {
      console.log(error)
    }

    dispatch(setLoading(false))
  }, [dispatch, props, getValues, isTripActive])

  // Initial input fields
  useEffect(() => {
    let formData = {}

    const getVessels = async () => {
      try {
        const result = await fetchGetvessels(props)
        if(!result.getVessels) return
        let sortedVesselList = [...result.getVessels]
        sortedVesselList = sortedVesselList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        sortedVesselList = sortedVesselList.sort((a,b) => (a.state > b.state) ? 1 : ((b.state > a.state) ? -1 : 0))
        sortedVesselList = sortedVesselList.map((vessel) => {
          let obj = Object.assign({}, vessel)
          obj.state = vessel.state.replace("_", " ")  // replace UNDER_REAPIR to UNDER REPAIR
          return obj
        })
        setVesselList(sortedVesselList)
      } catch (error) {
        console.log(error)
      }
    }
    getVessels()
    
    // Check which page user is on when adding/editing the trip
    switch(currentPath) {
      case ADD_PAGE:
        formData = {}
        setDisabled(false)
        setTripSaved(false)
        dispatch(setMapAlerts({
          bagAlerts: [],
          lockAlerts: [],
          msiAlerts: [],
          riverHeightAlerts: []
        }))
        break;
      case EDIT_ACTIVE_PAGE:
        formData = _.cloneDeep(startTripDetails)
        setDisabled(true)
        setTripSaved(true)
        setTripId(formData.id)
        checkDepartureTimeForPrevoyage(formData.departureDate)
        break;
      case EDIT_PLANNED_PAGE:
        formData = _.cloneDeep(editTripDetails)
        setDisabled(false)
        setTripId(formData.id)
        checkDepartureTimeForPrevoyage(formData.departureDate)
        formData.destinations = sortDestinations(formData.destinations)
        if(formData.origin && formData.destinations?.length > 0) {
          getCalculateTripData(formData.origin, formData.destinations, formData.departureDate)
        }
        setTripSaved(true)
        dispatch(setMapAlerts({
          bagAlerts: [],
          lockAlerts: [],
          msiAlerts: [],
          riverHeightAlerts: []
        }))
        break;
      default:
        formData = {}
        setDisabled(false)
        break;
    }

    let tripInfoDestinations = sortDestinations(formData?.destinations ? [...formData?.destinations] : [])
    setDestinations(tripInfoDestinations)

    let maxHeightFt = ""
    let maxHeightIn = ""
    let maxDraftFt = ""
    let maxDraftIn = ""
    if(formData.maxHeight) {
      maxHeightFt = formData.maxHeight.toString().split('.')[0]
      maxHeightIn = Math.round((parseFloat(formData.maxHeight) - parseInt(maxHeightFt)) * 12)
      if(unitMeasurement === 'm'){
        // ft to m
        maxHeightFt = Math.floor(ftTom(formData.maxHeight))
        maxHeightIn = Math.round(formData.maxHeight*30.48 - maxHeightFt*100)
      }
    }
    if(formData.maxDraft) {
      maxDraftFt = formData.maxDraft.toString().split('.')[0]
      maxDraftIn = Math.round((parseFloat(formData.maxDraft) - parseInt(maxDraftFt)) * 12)
      if(unitMeasurement === 'm'){
        // ft to m
        maxDraftFt = Math.floor(ftTom(formData.maxDraft))
        maxDraftIn = Math.round(formData.maxDraft*30.48 - maxDraftFt*100)
      }
    }
    reset({
      name: formData?.name ?? "",
      origin: formData?.origin ?? {},
      destinations: formData?.destinations ?? [],
      departureDate: formData?.departureDate ? moment(formData.departureDate) : moment.now(),
      vessel: formData?.vessel ?? null,
      maxDraftFt,
      maxDraftIn,
      maxHeightFt,
      maxHeightIn
    })

    setVesselState(!isEmpty(formData) ? formData.vessel.state : DEFAULT_VESSEL_STATE)
    setTripInfo(formData)

    // Please do not remove the comment below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reset, currentPath])

  // Get data if user is on edit trip
  useEffect(() => {
    const origin = getValues('origin')
    const destinations = getValues('destinations')

    let trafficLayerData = isEmpty(trafficLayer) ? tripInfo.trafficLayer : trafficLayer

    dispatch(setEditTripDetails({
      ...tripInfo, 
      origin,
      destinations,
      trafficLayer: trafficLayerData
    }))
  }, [tripInfo, getValues, dispatch, trafficLayer])

  // Download prevoyage csv
  useEffect(() => {
    if(isEmpty(prevoyageData)) return
    CSVRef.current.link.click()
  }, [prevoyageData])

  const downloadPrevoyage = async () => {
    if(!isCorrectInputs || disablePrevoyage) {
      warningToaster("Prevoyage is not available at this moment. It'll be available one hour prior to the departure time.")
      return
    }
    dispatch(setLoading(true))
    try {
      const result = await getCSVPrevoyage(props, {tripId})

      const origin = getValues('origin')
      const destinations = getValues('destinations')
      const filename = getValues('name')

      let csv = downloadCSV(result.getPrevoyagePlan, origin, destinations)
      csv = [
        csv.locationHeader,
        ...csv.locationData,
        [],
        csv.alertHeader,
        ...csv.data
      ]
      setPrevoyageData({
        csv,
        filename
      })
    } catch (error) {
      console.log(error)
      warningToaster("Prevoyage is not available at this moment. It'll be available one hour prior to the departure time.")
    }
    dispatch(setLoading(false))
  }

  const handleSearchLocationOnClick = (location, type, destIndex) => {
    if(location.name === "Location name not found") {
      errorToaster("Please select a valid location.")
      return
    }

    switch(type) {
      case ORIGIN_FIELD:
        setValue('origin', location)
        setTripInfo({...tripInfo, origin: location, trafficLayer: {}})
        break;
      case DESTINATION_FIELD:
        let destArr = getValues('destinations') ?? []
        destArr[destIndex] = {
          destination: location,
          legNumber: destIndex + 1
        }
        setValue('destinations', destArr)
        setDestinations(destArr)
        setTripInfo({...tripInfo, destinations: destArr, trafficLayer: {}})
        break;
      default:
        break;
    }

    if(!isEmpty(getValues('origin')) && getValues('destinations').length !== 0) {
      getCalculateTripData()
    }

    setTripSaved(false)
  }

  // Check if prevoyage is available to download
  const checkDepartureTimeForPrevoyage = (date) => {
    let timeDiff = moment(date).diff(moment(), 'hours')
    if(timeDiff <= 1 && timeDiff >= 0) {
      setDisablePrevoyage(false)
    }
    else {
      setDisablePrevoyage(true)
    }
  }

  const onDragEnd = e => {
    let destArr = getValues('destinations')
    if(destArr.length === 0) return

    // swap the destination order
    [destArr[e.source.index], destArr[e.destination.index]] = [destArr[e.destination.index], destArr[e.source.index]]

    // re-assign the leg number for each destination
    for(let index in destArr) {
      destArr[index].legNumber = parseInt(index) + 1
    }

    setValue('destinations', destArr)
    setDestinations(destArr)
    setTripInfo({...tripInfo, destinations: destArr})
    getCalculateTripData()
  }

  const removeDestination = (destIndex) => {
    let destArr = getValues('destinations')
    
    destArr.splice(destIndex, 1)

    // re-assign the leg number for each destination
    for(let index in destArr) {
      destArr[index].legNumber = parseInt(index) + 1
    }

    setValue('destinations', destArr)
    setDestinations(destArr)
    getCalculateTripData()
  }

  const removeErrors = name => {
    clearErrors(name)
  }

  const inputOnChange = e => {
    let inputName = e.target.name
    let inputVal = e.target.value
    let regExp = /[a-zA-Z]/g;

    setTripSaved(false)

    switch(inputName) {
      case "name":
        if(inputVal === "") {
          setError(inputName, {message: "Please enter a trip name"})
        }
        else {clearErrors(inputName)}
        break;
      case "origin":
      case "destinations":
        if(inputVal === "") {
          setError(inputName, {message: "Please select a location"})
        }
        else {clearErrors(inputName)}
        break;
      case "maxDraftFt":
        if(regExp.test(inputVal)){
          errorToaster("Please enter number only.")
          setError(inputName, {message: "Please enter number only"})
        }
        else if(unitMeasurement === 'ft' && inputVal && ((inputVal < 0 || inputVal > 12) || (inputVal % 1 !== 0))) {
          errorToaster("Max draft can only be between 0-12 ft.")
          setError(inputName, {message: "Max draft (ft) can only be between 0 to 12 ft"})
        }
        else if (unitMeasurement === 'm' && inputVal && ((inputVal < 0 || inputVal > 3) || (inputVal % 1 !== 0))){
          errorToaster("Max draft can only be between 0-3 m.")
          setError(inputName, {message: "Max draft (m) can only be between 0 to 3 m"})
        }
        else {clearErrors(inputName)}
        break;
      case "maxDraftIn":
        if(regExp.test(inputVal)){
          errorToaster("Please enter number only.")
          setError(inputName, {message: "Please enter number only"})
        }
        else if(unitMeasurement === 'ft' && inputVal && ((inputVal < 0 || inputVal > 12) || (inputVal % 1 !== 0))){
          errorToaster("Please enter number between 0 to 12 in.")
          setError(inputName, {message: "Max draft can only be between 0 to 12 in"})
        }
        else if(unitMeasurement === 'm' && inputVal && ((inputVal < 0 || inputVal > 100) || (inputVal % 1 !== 0))){
          errorToaster("Please enter number between 0 to 100 cm.")
          setError(inputName, {message: "Max draft can only be between 0 to 100 cm"})
        }
        else {clearErrors(inputName)}
        break;
      case "maxHeightFt":
        if(regExp.test(inputVal)){
          errorToaster("Please enter number only.")
          setError(inputName, {message: "Please enter number only"})
        }
        else if(unitMeasurement === 'ft' && inputVal && ((inputVal < 1 || inputVal > 100) || (inputVal % 1 !== 0))) {
          errorToaster("Max height can only be between 1 to 100 ft.")
          setError(inputName, {message: "Max height can only be between 0 to 100 ft"})
        }
        else if (unitMeasurement === 'm' && inputVal && ((inputVal < 1 || inputVal > 30) || (inputVal % 1 !== 0))) {
          errorToaster("Max height can only be between 1 to 30 m.")
          setError(inputName, {message: "Max height can only be between 0 to 30 m"})
        }
        else {clearErrors(inputName)}
        break;
      case "maxHeightIn":
        if(regExp.test(inputVal)){
          errorToaster("Please enter number only.")
          setError(inputName, {message: "Please enter number only"})
        }
        else if(unitMeasurement === 'ft' && inputVal && ((inputVal < 0 || inputVal > 12) || (inputVal % 1 !== 0))) {
          errorToaster("Please enter number between 0 to 12 in.")
          setError(inputName, {message: "Max height can only be between 0 to 12 in"})
        }
        else if(unitMeasurement === 'm' && inputVal && ((inputVal < 0 || inputVal > 100) || (inputVal % 1 !== 0))){
          errorToaster("Please enter number between 0 to 100 cm.")
          setError(inputName, {message: "Max height can only be between 0 to 100 cm"})
        }
        else {clearErrors(inputName)}
        break;
      default:
        break;
    }

    setIsCorrectInputs(Object.keys(errors).length === 0)
  }

  const onSubmit = async (data) => {
    dispatch(setLoading(true))

    // max draft
    let draftInToDec
    let maxDraft
    if(unitMeasurement === 'ft'){
      draftInToDec = parseInt(data.maxDraftIn === "" ? 0 : data.maxDraftIn) / 12
      maxDraft = parseFloat((parseInt(data.maxDraftFt) + draftInToDec).toFixed(2))
    }else{
      // convert from m to ft
      draftInToDec = parseInt(data.maxDraftIn === "" ? 0 : data.maxDraftIn)
      maxDraft = (parseInt(data.maxDraftFt) + (draftInToDec/100))
      maxDraft = parseFloat((maxDraft * 3.2808399).toFixed(2))
    }
    
    // max height
    let heightInToDec
    let maxHeight
    if(unitMeasurement === 'ft'){
      heightInToDec = parseInt(data.maxHeightIn === "" ? 0 : data.maxHeightIn) / 12
      maxHeight = parseFloat((parseInt(data.maxHeightFt) + heightInToDec).toFixed(2))
    }else{
      // convert from m to ft
      draftInToDec = parseInt(data.maxHeightIn === "" ? 0 : data.maxHeightIn)
      maxHeight = (parseInt(data.maxHeightFt) + (draftInToDec/100))
      maxHeight = parseFloat((maxHeight * 3.2808399).toFixed(2))
    }

    let tripData = {
      name: data.name,
      origin: data.origin.id,
      destinations: data.destinations.map(d => {
        return d.destination.id
      }),
      vesselId: data.vessel.id,
      departureDate: new Date(data.departureDate),
      maxDraft,
      maxHeight
    }

    if(tripCreated || currentPath !== ADD_PAGE) {  // edit trip
      try {
        const result = await editTrip(props, {
          ...tripData,
          id: tripId
        })
        if(!result) {
          dispatch(setLoading(true))
          navigate(`/start-trip/${tripId}`)
          return
        }

        if(currentPath !== EDIT_PLANNED_PAGE && isTripActive) {
          dispatch(setStartTripDetails({
            ...result.editTrip.trip,
            trafficLayer
          }))
          dispatch(setLoading(true))
          navigate(`/start-trip/${tripId}`)
        }
        else {
          dispatch(setEditTripDetails({
            ...result.editTrip.trip,
            trafficLayer
          }))
        }
        successToaster("Trip updated successfully!");
      } catch (error) {
        console.log("Create/Update trip error", error)
        errorToaster("Unable to update a trip at the moment. Please try again later.")
      }
    }
    else {  // create new trip
      try {
        const result = await addTrip(props, tripData)
        dispatch(setEditTripDetails({
          ...result.createTrip.trip,
          trafficLayer
        }))
        setTripId(result.createTrip.trip.id)
        successToaster("New trip created successfully!");
        setTripCreated(true)
      } catch (error) {
        setTripCreated(false)
        setTripSaved(true)
        errorToaster("Unable to create a trip at the moment. Please try again later.")
      }
    }

    setTripSaved(true)
    dispatch(setLoading(false))

    checkDepartureTimeForPrevoyage(data.departureDate)
  }

  const handleStartTrip = async () => {
    setStartTripClicked(true)
    dispatch(setLoading(true))
    try {
      const result = await startTrip(props, {
        id: tripId,
        isActive: true
      })
      if(!result) {
        dispatch(setLoading(false))
        setStartTripClicked(false)
        return
      }
      dispatch(setStartTripDetails({
        ...result.editTrip.trip,
        trafficLayer: trafficLayer
      }))
      dispatch(setTripActive(true))
      navigate(`/start-trip/${tripId}`)
      successToaster("Have a safe trip!");
    } catch (error) {
      console.log(error)
      dispatch(setLoading(false))
      setStartTripClicked(false)
      errorToaster("Unable to start a trip at the moment. Please try again later.")
    }
  }

  const handleTripStatusChange = (state) => {
    setTripStatus(state)
  }

  const handleReasonChange = (reason) => {
    setReason(reason)
  }

  if(tripStatus === PAUSE) {
    return (
      <PauseTrip 
        handleTripStatusChange={handleTripStatusChange} 
        tripId={startTripDetails.id}
        handleReasonChange={handleReasonChange}
      />
    )
  }

  if(tripStatus === END) {
    return (
      <EndTripComfirm 
        handleTripStatusChange={handleTripStatusChange}
        handleReasonChange={handleReasonChange}
        tripId={startTripDetails.id}
        reason={reason}
        origin={startTripDetails.origin}
        destinations={startTripDetails.destinations}
        name={startTripDetails.name}
      />
    )
  }

  return (
    <Detector
      onChange={e => dispatch(setHasInternet(e))}
      render={({online}) => (
        <div style={{
          width: '100%',
          maxHeight: showEta ? 'calc(100% - 68px)' : '98%',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}>
          {isTripActive &&
            <div className='mb-2' style={{width: "97%"}}>
              <ReturnToCurrentTrip />
            </div>
          }

          <form ref={formRef} className='trip-modal-form' onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register("name", {required: "Please enter a trip name"})}
              fullWidth
              label="Trip Name"
              variant='standard'
              autoComplete='off'
              error={!!errors.name}
              helperText={errors?.name?.message}
              onChange={inputOnChange}
              disabled={disabled}
            />

            <LocationSearch 
              {...register('origin', {required: "Please select an origin"})} 
              error={!!errors.origin}
              helperText={errors?.origin?.message}
              clearErrors={removeErrors}
              type={ORIGIN_FIELD}
              locationName={isEmpty(tripInfo) ? "" : tripInfo.origin}
              handleSearchLocationOnClick={handleSearchLocationOnClick} 
              disabled={disabled}
              onChange={inputOnChange}
            />

            <DraggableInputs 
              register={{...register('destinations', {required: "Please select a destination"})}}
              existingDestinations={destinationsData} 
              error={!!errors.destinations}
              helperText={errors?.destinations?.message}
              handleSearchLocationOnClick={handleSearchLocationOnClick}
              removeDestination={removeDestination}
              clearErrors={removeErrors}
              onDragEnd={onDragEnd}
              onChange={inputOnChange}
            /><br/>

            <Controller
              control={control}
              name="departureDate"
              rules={{ required: true }}
              render={({field: {onChange, value}}) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    className='trip-modal-text-field'
                    label="Departure Date (MM/DD/YYYY HH:mm)"
                    value={value}
                    minDate={disabled ? null : moment.now()}
                    inputFormat="MM/DD/YYYY HH:mm"
                    ampm={false}
                    disabled={disabled}
                    name="departureDate"
                    onChange={onChange}
                    onClose={() => {
                      setTripSaved(false)
                      getCalculateTripData()
                      setDisablePrevoyage(true)
                    }}
                    closeOnSelect={true}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        variant='standard' 
                        fullWidth
                        onChange={inputOnChange}
                        sx={{ svg: {color: PRIMARY_COLOR} }}
                    />}
                  />
                </LocalizationProvider>
              )}
            />

            <Controller
              control={control}
              name="vessel"
              rules={{ required: true }}
              render={({field: {onChange, value}}) => (
                <Autocomplete
                  className='trip-modal-text-field'
                  disableClearable
                  options={vesselList}
                  getOptionLabel={(option) => option.name && option.mmsi ? `${option.name} (${option.mmsi})` : ''}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  groupBy={(option) => option.state}
                  disabled={disabled}
                  onChange={(_, val) => {
                    setTripSaved(false)
                    onChange(val)
                    setVesselState(val.state)
                  }}
                  value={value ?? null}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label="Select a Vessel" 
                      variant="standard" 
                      error={!!errors.vessel}
                      helperText={errors?.vessel?.message}
                      sx={{ svg: {color: PRIMARY_COLOR} }}
                      required
                    />
                  }
                />
              )}
            />
            {vesselState !== "" && vesselState !== "AVAILABLE" && !isTripActive &&
              <small className='vessel-warning'>
                This vessel is curently {vesselState}
              </small>
            }


        <div className='d-flex justify-content-between'>
          <TextField
            className='trip-modal-text-field'
            label="Max Draft of Tow"
            variant="standard"
            autoComplete='off'
            {...register("maxDraftFt", { required: "Please enter a max draft value" })}
            error={!!errors.maxDraftFt}
            helperText={errors?.maxDraftFt?.message}
            onChange={inputOnChange}
            InputProps={{
              min: 0,
              max: unitMeasurement === 'ft' ? 12 : 3,
              step: 1,
              endAdornment: 
                <InputAdornment position="end">
                  <span style={{color: PRIMARY_COLOR}}>
                    {
                      unitMeasurement==='ft'?<b>ft.</b>:<b>m.</b>
                    }
                  </span>
                </InputAdornment>
            }}
            sx={{width: "45%"}}
          />
          <TextField
            className='trip-modal-text-field'
            label="Max Draft of Tow"
            variant="standard"
            autoComplete='off'
            {...register("maxDraftIn")}
            error={!!errors.maxDraftIn}
            helperText={errors?.maxDraftIn?.message}
            onChange={inputOnChange}
            InputProps={{
              min: 0,
              max: unitMeasurement === 'ft' ? 12 : 100,
              step: 1,
              endAdornment: 
                <InputAdornment position="end">
                  <span style={{color: PRIMARY_COLOR}}>
                    {
                      unitMeasurement==='ft'?<b>in.</b>:<b>cm.</b>
                    }
                  </span>
                </InputAdornment>
            }}
            sx={{width: "45%"}}
          />
        </div>

        <div className='d-flex justify-content-between'>
          <TextField
            className='trip-modal-text-field mb-4'
            label="Max Height"
            variant="standard"
            autoComplete='off'
            {...register("maxHeightFt", { required: "Please enter a max height value" })}
            error={!!errors.maxHeightFt}
            helperText={errors?.maxHeightFt?.message}
            onChange={inputOnChange}
            InputProps={{
              endAdornment: 
              <InputAdornment position="end">
                <span style={{color: PRIMARY_COLOR}}>
                  {
                    unitMeasurement==='ft'?<b>ft.</b>:<b>m.</b>
                  }
                </span>
              </InputAdornment>
            }}
            sx={{width: "45%"}}
          />
          <TextField
            className='trip-modal-text-field mb-4'
            label="Max Height"
            variant="standard"
            autoComplete='off'
            {...register("maxHeightIn")}
            error={!!errors.maxHeightIn}
            helperText={errors?.maxHeightIn?.message}
            onChange={inputOnChange}
            InputProps={{
              endAdornment: 
                <InputAdornment position="end">
                  <span style={{color: PRIMARY_COLOR}}>
                    {
                      unitMeasurement==='ft'?<b>in.</b>:<b>cm.</b>
                    }
                  </span>
                </InputAdornment>
            }}
            sx={{width: "45%"}}
          />
        </div>

            <div className='center justify-content-between'>
              <Button
                variant='contained'
                type='submit'
                disabled={!online || !isCorrectInputs || tripSaved}
                style={{
                  width: currentPath === EDIT_ACTIVE_PAGE ? '100%' : '80%'
                }}
              >
                <b>{(tripCreated || currentPath !== ADD_PAGE) ? "Update Trip" : "Create Trip"}</b>
              </Button>
              
              {currentPath !== EDIT_ACTIVE_PAGE &&
                <Button
                  id={(!isCorrectInputs || disablePrevoyage) ? "gray-out" : ""}
                  variant='contained'
                  onClick={downloadPrevoyage}
                >
                  <Tooltip 
                    arrow 
                    title="Download prevoyage"
                    enterDelay={500}
                  >
                    <FontAwesomeIcon icon={faFileArrowDown} fontSize={24} />
                  </Tooltip>
                </Button>
              }
            </div>

            {currentPath === EDIT_ACTIVE_PAGE && !isTripPaused &&
              <Button
                className='mt-3'
                variant='contained'
                fullWidth={true}
                onClick={() => {
                  if(!online) {
                    errorToaster("Please wait until you have internet connection.")
                    return
                  }
                  handleTripStatusChange(PAUSE)
                }}
              >
                Pause Trip
              </Button>
            }

            {(!isEmpty(prevoyageData) && prevoyageData.csv?.length > 0) &&
              <CSVLink
                ref={CSVRef}
                data={prevoyageData.csv}
                filename={`ripplego_prevoyage_${prevoyageData.filename}_${moment().format("YYYY-MM-DDTHHmm")}.csv`}
              />
            }


            {!isTripActive &&
              <Button
                className='mt-3'
                variant='contained'
                fullWidth
                onClick={handleStartTrip}
                disabled={startTripClicked || !isCorrectInputs || !tripSaved}
              >
                Start Trip
              </Button>
            }
          </form>

          {(showEta && getValues('destinations').length !== 0) &&
            <div className='trip-modal-eta'>
                <div>
                  {getValues('origin').city ?? getValues('origin').name}
                  {" "}to{" "}
                  {getValues('destinations').at(-1).destination.city?.toUpperCase() ?? getValues('destinations').at(-1).destination.name?.toUpperCase()}:
                </div>
                <div>ETA: {showEta}</div>
            </div>
          }
        </div>
      )}
    />
  )
}

export default withApollo(AddEditTrip)