import {
  SETUP_LOGIN,
  SET_LOADING,
  SET_LOGOUT,
  SET_TRIP_ACTIVE,
  SET_EDIT_TRIP_DETAILS,
  SET_START_TRIP_DETAILS,
  SET_DISPLAY_MODE,
  SET_MAP_ALERTS,
  SET_TRIP_PAUSED,
  SET_SELECT_ALERT,
  SET_POI_MARKERS,
  SET_SELECT_ALERT_CARD,
  SET_HIDE_MAP_LAYERS,
  SET_COLOR_BLIND,
  SET_UNIT_MEASUREMENT,
  SET_HAS_INTERNET,
} from "./types";

export const setupLogin = value => {
  return {
    type: SETUP_LOGIN,
    payload: value,
  };
};
export const logoutSession = value => {
  return {
    type: SET_LOGOUT,
    payload: value,
  };
};
export const setLoading = value => {
  return {
    type: SET_LOADING,
    payload: value
  }
};
export const setEditTripDetails = value => {
  return {
    type: SET_EDIT_TRIP_DETAILS,
    payload: value,
  };
};
export const setStartTripDetails = value => {
  return {
    type: SET_START_TRIP_DETAILS,
    payload: value,
  };
};
export const setTripActive = value => {
  return {
    type: SET_TRIP_ACTIVE,
    payload: value,
  };
};
export const setTripPaused = value => {
  return {
    type: SET_TRIP_PAUSED,
    payload: value,
  };
};
export const setDisplayMode = value => {
  return {
    type: SET_DISPLAY_MODE,
    payload: value,
  };
};
export const setMapAlerts = value => {
  return {
    type: SET_MAP_ALERTS,
    payload: value
  }
}
export const setSelectedAlert = value => {
  return {
    type: SET_SELECT_ALERT,
    payload: value
  }
}
export const setSelectedAlertCard = value => {
  return {
    type: SET_SELECT_ALERT_CARD,
    payload: value
  }
}
export const setPOIMarkers = value => {
  return {
    type: SET_POI_MARKERS,
    payload: value
  }
}
export const setHideMapLayers = value => {
  return {
    type: SET_HIDE_MAP_LAYERS,
    payload: value
  }
}
export const setColorBlind = value => {
  return {
    type: SET_COLOR_BLIND,
    payload: value
  }
}
export const setUnitMeasurement = value => {
  return {
    type: SET_UNIT_MEASUREMENT,
    payload: value
  }
}  
export const setHasInternet = value => {
  return {
    type: SET_HAS_INTERNET,
    payload: value
  }
}