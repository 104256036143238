import { Button } from '@mui/material'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { downloadCSV, resetReducerStates } from '../../Utils/helper'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setLoading } from '../../store/action'
import { getCSVDecklogs, startTrip } from '../../services'
import { errorToaster, successToaster } from '../../Utils/messageToast'
import { withApollo } from '@apollo/client/react/hoc'

function EndTripComfirm(props) {
  const decklogsCSVRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [decklogsData, setDecklogsData] = useState({})
  const [endTripClicked, setEndTripClicked] = useState(false)

  useEffect(() => {
    if(isEmpty(decklogsData)) return
    decklogsCSVRef.current.link.click()
    resetReducerStates(dispatch)
    setTimeout(() => {
      successToaster("Trip ended successfully!")
      navigate('/')
    }, 200);
  }, [decklogsData, dispatch, navigate])

  const reasonCategory = (reason) => {
    const UNKNOWN = "UNKNOWN"
    const INFO = "INFO"
    const WARNING = "WARNING"
    const DANGER = "DANGER"

    let color = ""

    switch(reason) {
      case "None":
        color = UNKNOWN
        break
      case "Cargo Delivered":
        color = INFO
        break
      case "Bad Weather Condition":
      case "Medical Emergency":
        color = DANGER
        break
      case "Coast Guard Inspection":
      case "Crew Change":
      case "Resupply":
      case "Refuel":
      case "Repairs":
        color = WARNING
        break
      default:  // Other
        color = WARNING
        break
    }

    return color
  }

  const downloadDecklog = async () => {
    dispatch(setLoading(true))
    try {
      const result = await getCSVDecklogs(props, {
        tripId: props.tripId
      })

      if(!result.getDecklogs) return

      let csv = downloadCSV(result.getDecklogs, props.origin, props.destinations)
      csv = [
        csv.locationHeader,
        ...csv.locationData,
        [],
        csv.alertHeader,
        ...csv.data
      ]
      setDecklogsData({
        csv,
        filename: props.name
      })
    } catch (error) {
      console.log(error)
    } 
    dispatch(setLoading(false))
  }

  const handleEndTrip = async () => {
    dispatch(setLoading(true))
    setEndTripClicked(true)
    try {
      const result = await startTrip(props, {
        id: props.tripId,
        isActive: false
      })
      if(!result) {
        setEndTripClicked(false)
        dispatch(setLoading(false))
        return
      }

      downloadDecklog()
    } catch (error) {
      console.log(error)
      setEndTripClicked(false)
      dispatch(setLoading(false))
      errorToaster("Unable to end the trip at this moment. Please try again later.")
    }
  }

  return (
    <div className='d-flex flex-column justify-content-between h-100'>
      <div>
        <h4>End Trip Confirmation</h4>
        <div className={'py-3 px-4 mt-3 reason-card ' + reasonCategory(props.reason)}>
          <h5>Reason for pausing: 
            <div className='mt-1'><b>{props.reason}</b></div>
          </h5>
        </div>
      </div>
      <div className='mb-5'>
        <Button
          className='mb-3'
          variant='contained'
          fullWidth
          onClick={handleEndTrip}
          disabled={endTripClicked}
        >
          End Trip
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={() => props.handleTripStatusChange("PAUSE")}
        >
          Cancel
        </Button>
      </div>

      {!isEmpty(decklogsData) && decklogsData.csv?.length > 0 &&
        <CSVLink
          ref={decklogsCSVRef}
          data={decklogsData.csv}
          filename={`ripplego_decklogs_${decklogsData.filename}_${moment().format("YYYY-MM-DDTHHmm")}.csv`}
        />
      } 
    </div>
  )
}

export default withApollo(EndTripComfirm)