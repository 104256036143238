import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LogoDark from '../../images/logos/logo-dark.png'
import LogoWhite from "../../images/logos/WhiteRippleGo.png"
import { useLocation, useNavigate } from 'react-router-dom'
import "./Login.scss"
import { passwordReset } from '../../services'
import { withApollo } from '@apollo/client/react/hoc'
import { successToaster } from '../../Utils/messageToast'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../store/action'

const DARK_DISPLAY_MODE = 'dark'

function ResetPassword(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const displayMode = useSelector(state => state.displayMode)

  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [errorMsg, setErrorMsg] = useState([])

  const handleReset = async (e) => {
    e.preventDefault()

    const token = currentPath.split('/')[2]
    if(password1 !== password2) {
      setErrorMsg(["Password does not match"])
      return
    }

    dispatch(setLoading(true))
    try {
      const result = await passwordReset(props, {
        token,
        newPassword1: password1,
        newPassword2: password2
      })

      if(!result.passwordReset.success) {
        let errorLists = []
        for(let error of result.passwordReset.errors.newPassword2) {
          errorLists.push(error.message)
        }
        setErrorMsg(errorLists)
      }
      else {
        successToaster("Password reset successfully!")
        navigate("/login")
      }
    } catch (error) {
      console.log(error)
    }
    dispatch(setLoading(false))

  }

  return (
    <>
      <div className={'login-img ' + (displayMode === DARK_DISPLAY_MODE ? 'darkmode' : "")}></div>
      <div className={'login-bg center shadow ' + (displayMode === DARK_DISPLAY_MODE ? 'darkmode' : "")}>
        <div className={'card login ' + (displayMode === DARK_DISPLAY_MODE ? 'login-dark' : "")} style={{zIndex: 3, width: '30%', minWidth: "350px", maxWidth: 500}}>
          <div className='card-body center flex-column'>
            <img className='my-4' src={ displayMode === DARK_DISPLAY_MODE ? LogoWhite : LogoDark} alt="RippleGo Logo" style={{width: '45%'}}/>
            <h5 className='mt-2'>Welcome to RippleGo</h5>
            <small>Reset password</small>
            <form className='mb-4' style={{width: '80%'}} onSubmit={handleReset}>
              {errorMsg !== "" && 
                <div className='text-start'>
                  <ul>
                    {errorMsg.map((msg, i) => (
                      <li key={i}><small className='text-danger'>{msg}</small></li>
                    ))}
                  </ul>
                </div>
              }

              <TextField
                className='mt-4'
                label="New password"
                variant='standard'
                type="password"
                fullWidth={true}
                onChange={e => setPassword1(e.target.value)}
                required
              />
              <TextField
                className='mt-4'
                label="Confirm new password"
                variant='standard'
                type="password"
                fullWidth={true}
                onChange={e => setPassword2(e.target.value)}
                required
              />
              <div className='mt-4 text-start' style={{opacity: 0.5}}>
                <ul>
                  <li><small>At least 8 characters.</small></li>
                  <li><small>Cannot be similar to username.</small></li>
                </ul>
              </div>
              <Button 
                className="mt-4 py-2"
                fullWidth={true}
                variant="contained"
                type='submit'
              >
                reset password
              </Button>
            </form>
            <Button 
              className="mt-5"
              variant="text"
              onClick={() => navigate('/login')}
            >
              <small>Back to login</small>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default  withApollo(ResetPassword)