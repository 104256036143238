import { Autocomplete, Button, TextField, TextareaAutosize } from '@mui/material'
import React, { useState } from 'react'
import { handleTripStatus } from '../../Utils/helper'
import { withApollo } from '@apollo/client/react/hoc'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const DEFAULT_REASON = "None"
const SAVE_AND_PAUSE = 'PAUSE'
const OTHER_REASON = 'Other'
const WARNING_COLOR= "#eea125"
const DANGER_COLOR = "#CD2C2C"

const REASON_OPTIONS = [
  {label: 'None'},
  {label: 'Cargo Delivered'},
  {label: 'Bad Weather Condition'},
  {label: 'Coast Guard Inspection'},
  {label: 'Crew Change'},
  {label: 'Resupply'},
  {label: 'Refuel'},
  {label: 'Repairs'},
  {label: 'Medical Emergency'},
  {label: OTHER_REASON}
]

function PauseTrip(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [reason, setReason] = useState(DEFAULT_REASON)
  const [otherReason, setOtherReason] = useState("")

  const buttonOnClick = (status) => {
    props.handleReasonChange((reason === OTHER_REASON ? otherReason : reason))
    props.handleTripStatusChange(status)
  }

  return (
    <div className='d-flex flex-column justify-content-between h-100'>
      <div>
        <h4 className='my-3'><strong>Reason for pausing trip</strong></h4>

        <Autocomplete
          disableClearable
          options={REASON_OPTIONS}
          value={reason}
          onChange={(_, val) => {
            setReason(val.label)
          }}
          renderInput={(params) => 
            <TextField
              {...params}
              label="Select a reason"
              variant='standard'
              fullWidth
            />
          }
        />

        {reason === OTHER_REASON &&
          <TextareaAutosize
            className='mt-3 mui-textarea'
            placeholder='Enter reason'
            color='primary'
            onChange={e => {
              setOtherReason(e.target.value)
            }}
          />
        }
      </div>

      <div className='mb-5'>
        <Button
          variant='contained'
          fullWidth
          onClick={() => handleTripStatus(props, dispatch, navigate, SAVE_AND_PAUSE, (reason === OTHER_REASON ? otherReason : reason), props.tripId)}
        >
          Save and <span style={{padding: '0 5px', color: WARNING_COLOR}}>Pause</span> Trip
        </Button>

        <Button
          className='my-3'
          variant='contained'
          fullWidth
          onClick={() => buttonOnClick("END")}
        >
          Save and <span style={{padding: '0 5px', color: DANGER_COLOR}}>End</span> Trip
        </Button>

        <Button
          variant='contained'
          fullWidth
          onClick={() => buttonOnClick("")}
        >
          Back To Edit
        </Button>
      </div>
    </div>
  )
}

export default withApollo(PauseTrip)