import {
  ADD_TRIP,
  FORGET_PASSWORD_MUTATION,
  GET_TRIPS,
  GET_VESSELS,
  LOGIN_AUTH,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  GET_ACTIVE_TRIPS,
  REFRESH_TOKEN,
  CALCULATE_TRIP,
  GET_TRAFFIC,
  EDIT_TRIP,
  START_TRIP,
  START_TRIP_SUBSCRIPTION,
  CREATE_TRIP_STATUS,
  CSV_DATA_DECKLOGS,
  CSV_DATA_PREVOYAGE,
  GET_LOCATION_SEARCH,
  REFRESH_ALERTS,
  GET_LOCATION,
  FETCH_ALERTS,
  GET_MILE_PATH,
} from "../graphql/query";
import { reactLocalStorage } from "reactjs-localstorage";
import { errorHandler } from "../Utils/helper";

/**
 * Auth function
 * @param {*} props pros of client
 * @param variables variable of email and password
 * @returns return token and refreshToken
 */
 export const loginAuth = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      context: { type: "notauth" },
      mutation: LOGIN_AUTH,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};
/**
 * generate reset link
 * @param {*} props pros of client
 * @param variables variable of email
 * @returns return status of the response
 */
export const forgetPassword = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      context: { type: "notauth" },
      mutation: FORGET_PASSWORD_MUTATION,
    })
    .then((response) => response.data)
    .catch((e) => console.log("error", e));
};

/**
 * fetch vessele list
 * @param {*} props pros of client
 * @returns return vessels list
 */
export const fetchGetvessels = async (props) => {
  return props.client
    .query({
      query: GET_VESSELS,
    })
    .then((response) => response.data)
    .catch((e) => console.log("error", e));
};

/**  Get All the trips
 * @returns return all trips
 */
export const fetchAllTrips = async (props, variables) => {
  return props.client
    .query({
      query: GET_TRIPS,
      fetchPolicy: "network-only",
      variables: variables
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Password Reset with token
 *  * @param {*} props pros of client,takes a newpassword1 and newpassword
 * @returns return success or error
 */
export const passwordReset = async (props, variables) => {
  return props.client
    .mutate({
      mutation: RESET_PASSWORD,
      variables: variables,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Change account password when user knows the old password.
 *  * @param {*} props pros of client,takes a newpassword1 and newpassword
 * @returns return success or error
 */
export const passwordChange = async (props, variables) => {
  return props.client
    .mutate({
      mutation: CHANGE_PASSWORD,
      variables: variables,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Add trip API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return status
 */
export const addTrip = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      mutation: ADD_TRIP,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};
/**
 * Create trip status API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return status
 */
export const createTripStatus = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      mutation: CREATE_TRIP_STATUS,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Calculate trip API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return ETA
 */
export const calculateTrip = async (props, variables) => {
  return props.client
    .query({
      variables: variables,
      query: CALCULATE_TRIP,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Edit trip API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return edit trip status
 */
export const editTrip = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      mutation: EDIT_TRIP,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Start trip API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return start trip status
 */
export const startTrip = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      mutation: START_TRIP,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};
/**
 * Get location search API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return ETA
 */
export const getLocationSearch = async (props, variables) => {
  return props.client
    .query({
      variables: variables,
      query: GET_LOCATION_SEARCH,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};
/**
 * Get traffic API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return ETA
 */
export const getTraffic = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      mutation: GET_TRAFFIC,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

export const fetchActiveTrips = async (props) => {
  return props.client
    .query({
      query: GET_ACTIVE_TRIPS,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

//handle Refresh Token
export const handleRefreshToken = (props) => {
  return props
    .mutate({
      variables: { refreshToken: `${reactLocalStorage.get("refreshToken")}` },
      mutation: REFRESH_TOKEN,
    })
    .then((response) => response.data)
    .catch((e) => {
      throw e;
    });
};

/**
 * subscription websocket API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return status
 */
export const subscriptionTrip = async (props, variables) => {
  return await props.client
    .subscribe({
      variables: variables,
      subscription: START_TRIP_SUBSCRIPTION,
      fetchPolicy: "network-first",
    });
};

/**
 * refresh Alert API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return status
 */
export const getRefreshAlert = async (props, variables) => {
  return await props.client
    .query({
      query: REFRESH_ALERTS,
      variables: variables,
    })
    .then((response) => response.data)
    .catch((e) => {
      throw e;
    });
};

/**
 * @param tripId
 * for downloading csv data
 */
export const getCSVDecklogs = async (props, variables) => {
  return await props.client
    .query({
      query: CSV_DATA_DECKLOGS,
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * @param tripId
 * for downloading csv data
 */
 export const getCSVPrevoyage = async (props, variables) => {
  return await props.client
    .query({
      query: CSV_DATA_PREVOYAGE,
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * @param SearchField
 * for downloading csv data
 */
export const GetSearchResult = async (props, variables) => {
  return await props.client
    .query({
      query: GET_LOCATION,
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * @param tripId
 * fetching alerts
 */
 export const fetchAlerts = async (props, variables) => {
  return await props.client
    .query({
      query: FETCH_ALERTS,
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * @param tripId
 * fetching mile markers
 */
 export const getMilePath = async (props, variables) => {
  return await props.client
    .query({
      query: GET_MILE_PATH,
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};
