import { faAngleLeft, faFileArrowDown, faGear, faHouse, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate} from 'react-router-dom'
import { Divider, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { AlertCircle, FileText, Info, MapPin, MessageSquare } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { logoutSession, setLoading } from '../../store/action'
import { CSVLink } from "react-csv";
import { getCSVDecklogs, getCSVPrevoyage } from '../../services'
import "./Navbar.scss"
import { withApollo } from '@apollo/client/react/hoc'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { downloadCSV } from '../../Utils/helper'
import ReportAndFeedbackForm from '../forms/reportAndFeedbackForm/ReportAndFeedbackForm'
import { errorToaster } from '../../Utils/messageToast'

const FEEDBACK = 'feedback'
const REPORT = 'report'
const PREVOYAGE = 'prevoyage'
const DECKLOGS = 'decklogs'
const EDIT_PAGE = "/manage-trip"
const ADD_PAGE = '/add'
const START_TRIP_PAGE = "/start-trip/"
const PLANNED_TRIP_PAGE = '/planned-trip'
const ICON_SIZE = 24
const PRIMARY_COLOR = "#008F85"
const PRIMARY_COLOR_Blind = "#19168c"
const DARK_DISPLAY_MODE = 'dark'

function Navbar(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const currentPath = location.pathname
  const prevoyageCSVRef = useRef(null)
  const decklogsCSVRef = useRef(null)
  const displayMode = useSelector(state => state.displayMode)
  const editTripDetails = useSelector(state => state.editTripDetails)
  const startTripDetails = useSelector(state => state.startTripDetails)
  const isTripActive = useSelector(state => state.isTripActive)
  const [anchorUser, setAnchorUser] = useState(null)
  const [anchorFile, setAnchorFile] = useState(null)
  const [prevoyageData, setPrevoyageData] = useState({})
  const [decklogsData, setDecklogsData] = useState([])
  const [openForm, setOpenForm] = useState(false)
  const [formType, setFormType] = useState("")
  const [disablePrevoyage, setDisablePrevoyage] = useState(true) // Disable prevoyage button if departure date didn't meet the condition

  const colorBlind = useSelector(state => state.colorBlind)
  const SLOT_PROPS = {
    paper: {
      elevation: 0,
      sx: {
        bgcolor: colorBlind? PRIMARY_COLOR_Blind: PRIMARY_COLOR,
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: colorBlind? PRIMARY_COLOR_Blind: PRIMARY_COLOR,
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        },
      }
    }
  }

  const openUserMenu = Boolean(anchorUser)
  const openFileMenu = Boolean(anchorFile)

  useEffect(() => {
    if(isEmpty(prevoyageData)) return
    prevoyageCSVRef.current.link.click()
  }, [prevoyageData])

  useEffect(() => {
    if(isEmpty(decklogsData)) return
    decklogsCSVRef.current.link.click()
  }, [decklogsData])

  useEffect(() => {
    // Check if prevoyage is available to download
    const checkDepartureTimeForPrevoyage = (date) => {
      let timeDiff = moment(date).diff(moment(), 'hours')
      if(timeDiff <= 1 && timeDiff >= 0) {
        setDisablePrevoyage(false)
      }
      else {
        setDisablePrevoyage(true)
      }
    }

    if(currentPath.includes(EDIT_PAGE)) {
      if(editTripDetails.departureDate) {
        checkDepartureTimeForPrevoyage(editTripDetails.departureDate)
      }
    }
    else if((currentPath === "/" || currentPath.includes(PLANNED_TRIP_PAGE) || currentPath.includes(START_TRIP_PAGE)) && isTripActive) {
      setDisablePrevoyage(false)
    }

  }, [editTripDetails, startTripDetails, currentPath, isTripActive])

  const handleLogout = () => {
    dispatch(logoutSession())
    navigate('/login')
  }

  const downloadCSVData = async (fileType) => {
    dispatch(setLoading(true))
    switch(fileType) {
      case PREVOYAGE:
        let tripId = ""
        let filename = ""
        let origin = {}
        let destinations = []
        let csvTripData = []

        if(currentPath.includes(EDIT_PAGE)) {
          csvTripData = editTripDetails
        }
        else if((currentPath === "/" || currentPath.includes(PLANNED_TRIP_PAGE) || currentPath.includes(START_TRIP_PAGE)) && isTripActive) {
          csvTripData = startTripDetails
        }

        tripId = csvTripData.id
        filename = csvTripData.name
        origin = csvTripData.origin
        destinations = csvTripData.destinations


        try {
          const result = await getCSVPrevoyage(props, {tripId})
          if(!result.getPrevoyagePlan) {
            errorToaster("Prevoyage is currently unavailable. Please try again later.")
            break;
          }
          
          let csv = downloadCSV(result.getPrevoyagePlan, origin, destinations)
          csv = [
            csv.locationHeader,
            ...csv.locationData,
            [],
            csv.alertHeader,
            ...csv.data
          ]

          setPrevoyageData({
            csv,
            filename
          })
        } catch (error) {
          console.log(error)
        }
        break;
      case DECKLOGS:
        try {
          const result = await getCSVDecklogs(props, {
            tripId: startTripDetails.id
          })

          if(!result.getDecklogs) {
            errorToaster("Decklog is currently unavailable. Please try again later.")
            break;
          }

          let csv = downloadCSV(result.getDecklogs, startTripDetails.origin, startTripDetails.destinations)
          csv = [
            csv.locationHeader,
            ...csv.locationData,
            [],
            csv.alertHeader,
            ...csv.data
          ]
          setDecklogsData({
            csv,
            filename: startTripDetails.name
          })
        } catch (error) {
          console.log(error)
        }
        break;
      default:
        break;
    }
    dispatch(setLoading(false))
  }

  const handleOpenForm = (type) => { 
    setFormType(type)
    setOpenForm(true) 
  }
  const handleCloseForm = () => { 
    setOpenForm(false) 
  }

  return (
    <>
      <div className={'w-100 center-y px-5 ' + (displayMode === DARK_DISPLAY_MODE ? 'dark-mode' : "")}>
        <IconButton 
          size="m"
          sx={{width: 40, height: 40}} 
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon className={'nav-icons '+(colorBlind ? 'color-blind-nav-btn' : "")} icon={faAngleLeft} fontSize={ICON_SIZE}/>
        </IconButton>
        
        <IconButton
          size="m"
          sx={{width: 40, height: 40}}
          onClick={() => navigate("/")}
        >
          <FontAwesomeIcon className={'nav-icons ' + (currentPath === "/" ? 'active' : "") +" "+(colorBlind ? 'color-blind-nav-btn' : "")} icon={faHouse} fontSize={ICON_SIZE}/>
        </IconButton>

        {/* File Menu */}
        <div>
          <IconButton
            size="m"
            sx={{width: 40, height: 40}}
            aria-controls={openFileMenu ? 'file-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openFileMenu ? 'true' : undefined}
            onClick={(e) => setAnchorFile(e.currentTarget)}
          >
            <FontAwesomeIcon className={'nav-icons ' + (openFileMenu ? 'active' : "") +" "+(colorBlind ? 'color-blind-nav-btn' : "")} icon={faFileArrowDown} fontSize={ICON_SIZE}/>
          </IconButton>
          <Menu
            anchorEl={anchorFile}
            id="file-menu"
            open={openFileMenu}
            onClose={() => setAnchorFile(null)}
            onClick={() => setAnchorFile(null)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            slotProps={SLOT_PROPS}
          >
            <div className='submenu info'>
              <MenuItem 
                onClick={() => downloadCSVData(PREVOYAGE)} 
                disabled={disablePrevoyage || (currentPath.includes(ADD_PAGE) && !editTripDetails.id)}
              >
                <div className='submenu'>
                  <MapPin /> 
                  <span>Prevoyage</span>
                  
                </div>
              </MenuItem>

              <Tooltip arrow title="Available to download prevoyage one hour prior to the departure time." style={{cursor: "help"}}>
                <Info />
              </Tooltip>
            </div>
            <div className='submenu info'>
              <MenuItem 
                onClick={() => downloadCSVData(DECKLOGS)}
                disabled={!isTripActive}
              >
                <div className='submenu'>
                  <FileText /> 
                  <span>Deck Logs</span>
                </div>
              </MenuItem>

              <Tooltip arrow title="Deck logs can be downloaded after the trip has started." >
                <Info />
              </Tooltip>
            </div>

          </Menu>
        </div>

        {/* User Menu */}
        <div>
          <IconButton
            size="m"
            sx={{width: 40, height: 40}}
            aria-controls={openUserMenu ? 'user-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openUserMenu ? 'true' : undefined}
            onClick={(e) => setAnchorUser(e.currentTarget)}
          >
            <FontAwesomeIcon className={'nav-icons ' + (openUserMenu ? 'active' : "")+" "+(colorBlind ? 'color-blind-nav-btn' : "")} icon={faUser} fontSize={ICON_SIZE}/>
          </IconButton>
          <Menu
            anchorEl={anchorUser}
            id="user-menu"
            open={openUserMenu}
            onClose={() => setAnchorUser(null)}
            onClick={() => setAnchorUser(null)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            slotProps={SLOT_PROPS}
          >
            <MenuItem onClick={() => handleOpenForm(FEEDBACK)}>
              <div className='submenu'>
                <MessageSquare className='submenu-icon' />
                <span className='user'>Feedback</span>
              </div>
            </MenuItem>
            <MenuItem onClick={() => handleOpenForm(REPORT)}>
              <div className='submenu'>
                <AlertCircle className='submenu-icon' />
                <span className='user'>Report Issue</span>
              </div>
            </MenuItem>
            <Divider className='bg-white'/>
            <MenuItem onClick={handleLogout}>
              <div className='submenu'>
                <span className='user'>Log Out</span>
              </div>
            </MenuItem>
          </Menu>
        </div>

        <IconButton
          size="m"
          sx={{width: 40, height: 40}}
          onClick={() => navigate('/settings')}
        >
          <FontAwesomeIcon className={'nav-icons ' + (currentPath === "/settings" ? 'active' : "")+" "+(colorBlind ? 'color-blind-nav-btn' : "")} icon={faGear} fontSize={ICON_SIZE}/>
        </IconButton>

      </div>

      {(!isEmpty(prevoyageData) && prevoyageData.csv?.length > 0) &&
        <CSVLink 
          ref={prevoyageCSVRef}
          data={prevoyageData.csv}
          filename={`Ripplego_Prevoyage_${prevoyageData.filename}_${moment().format("YYYY-MM-DDTHHmm")}.csv`}
        />
      }
      {(!isEmpty(decklogsData) && decklogsData.csv?.length > 0) &&
        <CSVLink 
          ref={decklogsCSVRef}
          data={decklogsData.csv}
          filename={`Ripplego_Decklogs_${decklogsData.filename}_${moment().format("YYYY-MM-DDTHHmm")}.csv`}
        />
      }

      <ReportAndFeedbackForm 
        openForm={openForm}
        handleCloseForm={handleCloseForm}
        formType={formType}
        displayMode={displayMode}
      />
    </>
  )
}

export default withApollo(Navbar)