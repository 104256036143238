import AddEditTrip from "../pages/addEditTrip/AddEditTrip";
import Dashboard from "../pages/dashboard/Dashboard";
import NotFound from "../pages/NotFound"
import PlannedTrip from "../pages/plannedTrip/PlannedTrip";
import Settings from "../pages/settings/Settings";
import StartTrip from "../pages/startTrip/StartTrip";

const Routers = [
    {
        path: '/',
        component: <Dashboard/>,
    },
    {
        path: '/settings',
        component: <Settings/>,
    },
    {
        path: '/manage-trip/:type',
        component: <AddEditTrip/>,
    },
    {
        path: '/start-trip/:id',
        component: <StartTrip/>,
    },
    {
        path: '/planned-trip',
        component: <PlannedTrip />
    },
    {
        path: '*',
        component: <NotFound/>,
    },
]

export default Routers