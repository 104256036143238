import { faArrowsUpDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Detector } from "react-detect-offline";
import { errorToaster } from "../../Utils/messageToast";
import LocationSearch from './LocationSearch';
import { useDispatch } from "react-redux";
import { setHasInternet } from "../../store/action";

const DESTINATION_FIELD = 'DESTINATIONS'

export default function DraggableInputs({
  handleSearchLocationOnClick,
  removeDestination,
  helperText,
  error,
  clearErrors,
  register,
  existingDestinations,
  disabled,
  onDragEnd,
  onChange
}) {
  const [destinations, setDestinations] = useState(existingDestinations.length > 0 ? existingDestinations : [{}])
  const dispatch = useDispatch()

  useEffect(() => {
    let destinations = existingDestinations.length > 0 ? [...existingDestinations] : [{}]

    if(destinations.length > 1) {
      destinations = destinations.sort((a, b) => a.legNumber - b.legNumber)
    }
    
    setDestinations(destinations)

  }, [existingDestinations])

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: "transparent",
    ...draggableStyle
  });

  return (
    <Detector
      onChange={e => dispatch(setHasInternet(e))}
      render={({online}) => (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                >
                  {destinations.map((dest, key) => (
                    <Draggable key={key} draggableId={key.toString()} index={key}>
                      {(provided, snapshot) => (
                        <div
                          className='d-flex align-items-end'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                          )}
                          >
                          <div {...provided.dragHandleProps}>
                            {(!disabled && destinations?.length > 1) &&
                              <FontAwesomeIcon className="me-2" icon={faArrowsUpDown} color="#008F85" size='sm' style={{marginBottom: 6}}/>
                            }
                          </div>
                          <LocationSearch 
                            {...register} 
                            error={error}
                            helperText={helperText}
                            handleSearchLocationOnClick={handleSearchLocationOnClick} 
                            type={DESTINATION_FIELD} 
                            removeDestination={() => { removeDestination(key) }}
                            destNum={key+1}
                            clearErrors={clearErrors}
                            locationName={dest?.destination}
                            disabled={disabled}
                            key={key}
                            onChange={onChange}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
    
          {!disabled &&
            <Button
              id="plus-btn"
              variant='contained'
              className='mt-2 p-0'
              onClick={() => { 
                if(!online) {
                  errorToaster("Please wait until you have internet connection.")
                  return
                }
                setDestinations(prevArr => [...prevArr, {}]) 
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          }
        </>
      )}
    />
  )
}
