import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReturnToCurrentTrip from '../../components/returnToCurrentTrip/ReturnToCurrentTrip'
import { Button, InputAdornment, Tab, Tabs, TextField } from '@mui/material'
import { withApollo } from '@apollo/client/react/hoc'
import { fetchAllTrips } from '../../services'
import { ArrowRight, Search } from 'react-feather'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setEditTripDetails, setLoading } from '../../store/action'
import { sortDestinations } from '../../Utils/helper'
import { errorToaster } from '../../Utils/messageToast'

const DEFAULT_TAB = 'all'
const PRIMARY_COLOR = "#008F85"

function PlannedTrip(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isTripActive = useSelector(state => state.isTripActive)
  const loading = useSelector(state => state.loading)
  const [tabVal, setTabVal] = useState(DEFAULT_TAB)
  const [plannedTrips, setPlannedTrips] = useState([])
  const [filterPlannedTrips, setFilterPlannedTrips] = useState ([])
  const [timePeriod, setTimePeriod] = useState(null)

  useEffect(() => {
    dispatch(setEditTripDetails({}))
    const getPlannedTrips = async () => {
      dispatch(setLoading(true))
      try {
        const result = await fetchAllTrips(props, {minDate: moment().subtract(1, 'day')})
        let trips = []
        for(let trip of result.getTrips) {
          if(trip.statuses.length > 0) continue
          trips.push({
            ...trip,
            destinations: sortDestinations(trip.destinations)
          })
        }

        trips = trips.sort((a,b) => new Date(a.departureDate).getTime() - new Date(b.departureDate).getTime())
        setPlannedTrips(trips)
        setFilterPlannedTrips(trips)
      } catch (error) {
        console.log(error)
        errorToaster('Unable to fetch planned trips at the moment. Please try again later.')
      }
      dispatch(setLoading(false))
    }

    getPlannedTrips()
  }, [props, dispatch])

  const tabOnChange = (_, val) => {
    setTabVal(val)
    let filteredTrips = [...plannedTrips]
   
    if(val !== 'all') {
      filteredTrips = filteredTrips.filter(trip => moment(trip.departureDate).isBetween(moment().subtract(1, 'day'), moment().add(1, val)))
      setTimePeriod(moment().add(1, val).format("MMM DD, YYYY"))
    }
    else {
      setTimePeriod(null)
    }
    setFilterPlannedTrips(filteredTrips)
  }

  const handleSearchTripName = e => {
    let filteredTrips = [...plannedTrips]

    filteredTrips = filteredTrips.filter(trip => trip.name.includes(e.target.value))
    setFilterPlannedTrips(filteredTrips)
  }

  return (
    <div className='h-100'>
      {isTripActive && <ReturnToCurrentTrip /> }

      <h4 className='my-3'><strong>Planned Trips</strong></h4>

      <TextField
        className='mb-2'
        fullWidth
        label="Search trip name"
        variant='standard'
        InputProps={{
          endAdornment:
            <InputAdornment position='end'>
              <Search color={PRIMARY_COLOR} />
            </InputAdornment>
        }}
        onChange={handleSearchTripName}
      />

      <Tabs value={tabVal} onChange={tabOnChange} variant="fullWidth">
        <Tab label="Weekly" value="week"/>
        <Tab label="Monthly" value="month"/>
        <Tab label="Yearly" value="year"/>
        <Tab label="All" value="all"/>
      </Tabs>

      {timePeriod &&
        <div className='text-center my-2' style={{opacity: 0.8}}>
          <small>
            <b>{moment().subtract(1, 'day').format("MMM DD, YYYY")}</b> to <b>{timePeriod}</b>
          </small>
        </div>
      }

      {filterPlannedTrips.length > 0 ?
        <div className='mt-3 w-100' style={{maxHeight: 'calc(100% - 280px)', overflowY: 'auto'}}>
          {filterPlannedTrips.map((trip, i) => (
            <Button 
              className='mb-3'
              key={i}
              variant='outlined'
              fullWidth
              style={{
                textTransform: 'none',
                height: '120px',
              }}
              onClick={() => {
                navigate(`/manage-trip/edit-planned:${trip.id}`)
                dispatch(setEditTripDetails(trip))
              }}
            >
              <div className='w-100'>
                <u>{trip.name}</u> <br/>
                <div className='center text-uppercase'>
                  <div className='text-truncate'>{trip.origin.city ?? trip.origin.name} </div>
                  <ArrowRight className='mx-1' size={16}/> 
                  <div className='text-truncate'>{trip.destinations.at(-1).destination.city ?? trip.destinations.at(-1).destination.name}</div>
                </div>
                {trip.vessel.name} &#40;{trip.vessel.mmsi}&#41; <br/>
                {moment(trip.departureDate).format("MMM DD, YYYY HH:mm")}
              </div>
            </Button>
          ))}
        </div>
        :
        <div className='mt-3 text-center'>
          {!loading && "No planned trip"} 
        </div>
      }

    </div>
  )
}

export default withApollo(PlannedTrip)