import { createTheme } from "@mui/material/styles";


const PRIMARY_COLOR = "#008F85"
const PRIMARY_COLOR_COLOR_BLIND = "#19168c"
const SECONDARY_COLOR = "#004251"
const DANGER_COLOR = "#CD2C2C"
const DANGER_DARK_COLOR = "#500200"
const WARNING_COLOR= "#eea125"
const WARNING_DARK_COLOR = "#dc8d0e"
const GRAY = "#e0e0e0"
const DARKAR_GRAY = "#b1b1b1"

const baseTheme = {
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      darker: SECONDARY_COLOR,
      contrastText: '#fff'
    },
    danger: {
      main: DANGER_COLOR,
      darker: DANGER_DARK_COLOR,
      contrastText: '#fff'
    },
    warning: {
      main: WARNING_COLOR,
      darker: WARNING_DARK_COLOR,
      contrastText: '#fff'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: 55,
          fontSize: 15,
          fontWeight: 600,
          ...(ownerState.variant === 'outlined' && 
            {
              '&:hover': {
                backgroundColor: PRIMARY_COLOR,
                color: 'white'
              }
            }
          ),
          ...(ownerState.color === 'danger' && 
            {
              '&:hover': {
                backgroundColor: DANGER_DARK_COLOR,
                color: 'white'
              }
            }
          ),
          ...(ownerState.id === 'plus-btn' &&
            {
              height: 35,
            }
          ),
          ...(ownerState.id === 'gray-out' &&
            {
              backgroundColor: GRAY,
              boxShadow: 'none',
              color: DARKAR_GRAY,
              '&:hover': {
                cursor: 'default',
                boxShadow: 'none',
                backgroundColor: GRAY,
              }
            }
          )
        })
      }
    },
    MuiTextareaAutosize: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.id === 'textarea' &&
            {
              backgroundColor: PRIMARY_COLOR,
              width: '100%',
            }
          )
        })
      }
    }
  }
}

const baseThemeColorBlind = {
  palette: {
    primary: {
      main: PRIMARY_COLOR_COLOR_BLIND,
      darker: SECONDARY_COLOR,
      contrastText: '#fff'
    },
    danger: {
      main: DANGER_COLOR,
      darker: DANGER_DARK_COLOR,
      contrastText: '#fff'
    },
    warning: {
      main: WARNING_COLOR,
      darker: WARNING_DARK_COLOR,
      contrastText: '#fff'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: 55,
          fontSize: 15,
          fontWeight: 600,
          ...(ownerState.variant === 'outlined' && 
            {
              '&:hover': {
                backgroundColor: PRIMARY_COLOR_COLOR_BLIND,
                color: 'white'
              }
            }
          ),
          ...(ownerState.color === 'danger' && 
            {
              '&:hover': {
                backgroundColor: DANGER_DARK_COLOR,
                color: 'white'
              }
            }
          ),
          ...(ownerState.id === 'plus-btn' &&
            {
              height: 35,
            }
          ),
          ...(ownerState.id === 'gray-out' &&
            {
              backgroundColor: GRAY,
              boxShadow: 'none',
              color: DARKAR_GRAY,
              '&:hover': {
                cursor: 'default',
                boxShadow: 'none',
                backgroundColor: GRAY,
              }
            }
          )
        })
      }
    },
    MuiTextareaAutosize: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.id === 'textarea' &&
            {
              backgroundColor: PRIMARY_COLOR_COLOR_BLIND,
              width: '100%',
            }
          )
        })
      }
    }
  }
}

export const theme = createTheme({
  ...baseTheme
})

export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'dark'
  }
})

export const themeColorBlind = createTheme({
  ...baseThemeColorBlind
})

export const darkThemeColorBlind = createTheme({
  ...baseThemeColorBlind,
  palette: {
    ...baseThemeColorBlind.palette,
    mode: 'dark'
  }
})