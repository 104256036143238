import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import { withApollo } from "@apollo/client/react/hoc";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch } from "react-redux";
import LogoDark from '../../images/logos/logo-dark.png'
import LogoWhite from "../../images/logos/WhiteRippleGo.png"
import {forgetPassword, loginAuth} from '../../services'
import { setLoading, setupLogin } from '../../store/action';
import "./Login.scss"
import { useSelector } from 'react-redux';
import { errorToaster, successToaster } from '../../Utils/messageToast';
import { Checkbox, FormControlLabel, FormGroup, InputLabel } from '@mui/material';
import BetaAgreementForm from '../../components/forms/agreementForm/BetaAgreementForm';

const DARK_DISPLAY_MODE = 'dark'

function Login(props) {
  const displayMode = useSelector(state => state.displayMode)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [incorrect, setIncorrect] = useState(false)
  const [forgotPswd, setForgotPswd] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'RippleGo | Login'
    dispatch(setLoading(false))
  }, [dispatch])
 
  const handleLogin = async e => {
    e.preventDefault()

    if(forgotPswd) {
      try{
        const result = await forgetPassword(props, {email})
        if(result?.sendPasswordResetEmail?.success) {
          successToaster("Email sent! You should receive the email within a couple of minutes.")
        }
      } catch (error) {
        errorToaster("Email is invalid. Please try again.")
        console.log(error)
      }
    }
    else {
      let key = 'username'
      if(username.includes('@')) {
        key = 'email'
      }
      const login = await loginAuth(props, {
        [key]: username,
        password,
      })
  
      if(login && login.tokenAuth?.token) {
        let tokenAuth = login.tokenAuth;
        if(tokenAuth.success) {
          dispatch(
            setupLogin({
              token: tokenAuth.token,
              refreshToken: tokenAuth.refreshToken,
              user: tokenAuth.user,
            })
          );
        }
        navigate('/')
      }
      else {
        setIncorrect(true)
        errorToaster("Incorrect username or password. Please try again.")
      }
    }
  }

  const handleForgotPassword = () => {
    setForgotPswd(!forgotPswd)
  }

  const betaAgreementForm = () => {
    setModalOpen(true)
  }

  return (
    <>
      <div className={'login-img ' + (displayMode === DARK_DISPLAY_MODE ? 'darkmode' : "")}></div>
      <div className={'login-bg center shadow ' + (displayMode === DARK_DISPLAY_MODE ? 'darkmode' : "")}>
        <div className={'card login ' + (displayMode === DARK_DISPLAY_MODE ? 'login-dark' : "")} style={{zIndex: 3, width: '30%', minWidth: "350px", maxWidth: 500}}>
          <div className='card-body center flex-column'>
            <img className='my-4' src={ displayMode === DARK_DISPLAY_MODE ? LogoWhite : LogoDark} alt="RippleGo Logo" style={{width: '45%'}}/>
            <h5 className='mt-2'>Welcome to RippleGo</h5>
            <small>{forgotPswd ? "Please enter your email" : "Log in to start planning your trips"}</small>
            <form className='mt-3 mb-4' style={{width: '80%'}} onSubmit={handleLogin}>
              {incorrect && <div className='center'><small className='text-danger'>Incorrect username or password</small></div>}
              {forgotPswd ? 
                <>
                  <TextField
                    className='mt-4'
                    label="Email"
                    variant='standard'
                    fullWidth={true}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                </>
                :
                <>
                  <TextField
                    className='mt-4'
                    label="Username or Email"
                    variant='standard'
                    fullWidth={true}
                    onChange={e => setUsername(e.target.value)}
                    required
                  />
                  <TextField
                    className='mt-4'
                    label="Password"
                    variant='standard'
                    type="password"
                    fullWidth={true}
                    onChange={e => setPassword(e.target.value)}
                    required
                  />
                </>
              }

              {!forgotPswd &&
                <FormGroup className=' mt-5'>
                  <FormControlLabel required control={<Checkbox />} label={<InputLabel>I accept the terms of RippleGo's <span className='beta-agreement-text' onClick={betaAgreementForm}><br/>Beta Test Agreement</span></InputLabel>}/>
                </FormGroup>
              }
              
              <Button 
                className="mt-3 py-2"
                type="submit"
                fullWidth={true}
                variant="contained"
              >
                {forgotPswd ? "request reset link" : "log in"}
              </Button>
            </form>
            <Button 
              className="mt-5"
              variant="text"
              onClick={handleForgotPassword}
            >
              <small>{forgotPswd ? "Back to login" : "Forgot Password?"}</small>
            </Button>
          </div>
        </div>
      </div>

      <BetaAgreementForm
        modalOpen={modalOpen}
        modalOnClose={() => setModalOpen(false)}
      />
    </>
  )
}

export default withApollo(Login);
