import { ThemeProvider } from "@mui/system";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import RouterLists from "./routers/Routers"
import Login from "./pages/login/Login";
import MapView from "./pages/mapView/MapView";
import Navbar from "./components/navbar/Navbar";
import LoadingGIF from "./images/boat.gif"
import { ToastContainer } from 'react-toastify';
import { withApollo } from "@apollo/client/react/hoc";
import { darkTheme, theme, themeColorBlind, darkThemeColorBlind } from "./themes";
import './GlobalStyling.scss';
import 'react-toastify/dist/ReactToastify.css';
import ResetPassword from "./pages/login/ResetPassword";

const GRAY_COLOR = "#E6E6E6"
const DARK_GRAY_COLOR = "#212121"
const DARK_DISPLAY_MODE = 'dark'

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname

  const isLogin = useSelector(state => state.isLogin)
  const loading = useSelector(state => state.loading)
  const displayMode = useSelector(state => state.displayMode)
  const colorBlind = useSelector(state => state.colorBlind)

  useEffect(() => {
    if(!isLogin) {
      if(currentPath.includes('/password-reset/')) {
        return navigate(currentPath)
      }
      return navigate('login')
    }
    else if(currentPath === "/login") {  // re-direct user back to dashboard page if they are logged in already
      return navigate('/')
    }
  }, [isLogin, currentPath, navigate])

  return (
    <ThemeProvider theme={
      displayMode === DARK_DISPLAY_MODE && colorBlind ? darkThemeColorBlind :
      displayMode === DARK_DISPLAY_MODE && !colorBlind ? darkTheme :
      displayMode !== DARK_DISPLAY_MODE && colorBlind ? themeColorBlind : theme
    }>  
      {loading &&
        <img className="loading-img" src={LoadingGIF} alt="Loading..." />
      }
      <div
        style={{
          opacity: loading ? 0.6 : 1,
          pointerEvents: loading ? 'none' : 'auto'
        }}
      >
        {isLogin ? 
          <div className="d-flex">
            <div style={{
              width: '75%',
              background: displayMode === DARK_DISPLAY_MODE ? DARK_GRAY_COLOR : GRAY_COLOR
            }}>
              <MapView />
            </div>

            <div style={{
              width: '25%',
              minWidth: 450,
              height: '100vh',
              boxShadow: "0 4px 30px 8px rgb(0 0 0/15%)",
              zIndex: 2,
              transition: '0.6s ease all',
              backgroundColor: displayMode === DARK_DISPLAY_MODE ? DARK_GRAY_COLOR : 'white',
              color: displayMode === DARK_DISPLAY_MODE ? 'white' : DARK_GRAY_COLOR,
              overflowY: 'scroll'
            }}>
              <div className="center" style={{
                height: 50,
                margin: '8px 0'
              }}>
                <Navbar/>
              </div>
              <div style={{
                padding: '3% 4% 0',
                height: 'calc(100vh - 66px)',
                position: 'relative'
              }}>
                <ToastContainer 
                  bodyClassName="toastBody"
                  theme="colored"
                />
                <Routes>
                  {RouterLists.map((route, key) => (
                    <Route key={key} path={route.path} element={route.component}/>
                  ))}
                </Routes>
              </div>
            </div>
          </div>
          :
          <>
            <ToastContainer 
              bodyClassName="toastBody"
              theme="colored"
            />
            <Routes>
              <Route path="login" element={<Login />}/>
              <Route path="password-reset/:token" element={<ResetPassword />}/>
            </Routes>
          </>
        }
      </div>
    </ThemeProvider>
  );
}

export default withApollo(App);
