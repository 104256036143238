import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"
import { withApollo } from '@apollo/client/react/hoc'
import { __DEV__ } from '@apollo/client/utilities/globals'
import { useSubscription } from '@apollo/react-hooks'
import { faBell, faBridgeWater, faLock, faWater } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from '@mui/material'
import _, { isEmpty } from "lodash"
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Detector } from "react-detect-offline"
import { RefreshCw, WifiOff } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { calculateEtaHoursAndMinutes, handleCalculateTrip, handleTripStatus, sortAlerts, ftTom } from '../../Utils/helper'
import { errorToaster } from '../../Utils/messageToast'
import { START_TRIP_SUBSCRIPTION } from '../../graphql/query'
import { fetchActiveTrips, fetchAlerts, getRefreshAlert } from '../../services'
import { setHasInternet, setLoading, setSelectedAlertCard, setStartTripDetails } from '../../store/action'
import Chart from './Chart'
import "./StartTrip.scss"

const BRIDGE_ALERT_TYPE = "bridge"
const LOCK_ALERT_TYPE = "lock"
const MSI_ALERT_TYPE = "msi"
const RIVER_ALERT_TYPE = "river"
const RESUME_TRIP = "RESUME"
const PRIMARY_COLOR = "#008F85"

if(__DEV__) {
  loadDevMessages();
  loadErrorMessages();
}

function StartTrip(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const startTripDetails = useSelector(state => state.startTripDetails)
  const isTripPaused = useSelector(state => state.isTripPaused)
  const selectedAlert = useSelector(state => state.selectedAlert)
  const colorBlind = useSelector(state => state.colorBlind)
  const unitMeasurement = useSelector(state => state.unitMeasurement)
  const hasInternet = useSelector(state => state.hasInternet)

  const [tripAlerts, setTripAlerts] = useState([])
  const [readMoreMSI, setReadMoreMSI] = useState([])
  const [showCharts, setShowCharts] = useState([])
  const [showEta, setShowEta] = useState(null)
  const [refreshClicked, setRefreshClicked] = useState(false)

  const {loading} = useSubscription(
    START_TRIP_SUBSCRIPTION,
    {
      variables: {id: startTripDetails.id},
      fetchPolicy: "network-only",
      nextFetchPolicy: 'cache-first',
      onData: (data) => {
        let newTripData = data.data.data.tripSubscription?.trip
        if(!newTripData) {
          getRefreshAlert(props, {tripId: startTripDetails.id}).then(response => {
            const result = response.refreshAlerts
            if(!result) return
            setTripAlerts(sortAlerts(result, dispatch)) 
          })
          return
        }

        const getCalculateTripData = async () => {
          const calculateTripData = await handleCalculateTrip(props, dispatch, newTripData.origin, newTripData.destinations, newTripData.departureDate, true)
          dispatch(setStartTripDetails({
            ...newTripData,
            trafficLayer: calculateTripData.trafficLayer
          }))
        } 
        
        getCalculateTripData()
      }
    }
  )

  useEffect(() => {
    if(isEmpty(selectedAlert)) return

    let alertCard = document.getElementById(selectedAlert.id)
    if(!alertCard) return

    alertCard.scrollIntoView({behavior: 'smooth'})
  }, [selectedAlert])

  useEffect(() => {
    if(hasInternet) { dispatch(setLoading(loading)) }
    else {dispatch(setLoading(false))}
  }, [loading, dispatch, hasInternet])

  useEffect(() => {
    if(startTripDetails?.destinations?.length > 0) {
      setShowEta(calculateEtaHoursAndMinutes(moment.now(), Math.round(startTripDetails.destinations.at(-1).eta)))
    }

    if(isEmpty(startTripDetails)) {
      const getTrip = async () => {
        try {
          const result = await fetchActiveTrips(props)
          if(result.getTrips?.length !== 0 && result.getTrips[0].statuses.at(-1).status !== "END") {
            const tripDetails = _.cloneDeep(result.getTrips[0])
            const calculateTripData = await handleCalculateTrip(props, dispatch, tripDetails.origin, tripDetails.destinations, tripDetails.departureDate, true)
            dispatch(setStartTripDetails({
              ...tripDetails,
              trafficLayer: calculateTripData.trafficLayer
            }))
          }
        } catch (error) {
          console.log(error)
        }
      }

      getTrip()
      return
    }
    setTripAlerts(sortAlerts(startTripDetails, dispatch)) 
  }, [startTripDetails, dispatch, props])

  const refetchAlerts = async () => {
    try {
      const result = await fetchAlerts(props, {tripId: startTripDetails.id})
      if(!result?.fetchAlerts?.success) {
        errorToaster("Unable to fetch alerts at hte moment. Please try again later.")
        return
      }
    } catch (error) {
      console.log(error)
    }
  }

  const alertIcon = (type, severity) => {
    switch(type) {
      case BRIDGE_ALERT_TYPE:
        return <FontAwesomeIcon className={'trip-alert-icon ' + severity + (colorBlind ? " colorBlind": "")} icon={faBridgeWater} />
      case LOCK_ALERT_TYPE:
        return <FontAwesomeIcon className={'trip-alert-icon ' + severity + (colorBlind ? " colorBlind": "")} icon={faLock} />
      case MSI_ALERT_TYPE:
        return <FontAwesomeIcon className={'trip-alert-icon ' + severity + (colorBlind ? " colorBlind": "")} icon={faBell} />
      case RIVER_ALERT_TYPE:
        return <FontAwesomeIcon className={'trip-alert-icon ' + severity + (colorBlind ? " colorBlind": "")} icon={faWater} />
      default:
        return
    }
  }

  const showMoreMSI = (id) => {
    if(!readMoreMSI.includes(id)) {
      setReadMoreMSI(prevArr => [...prevArr, id])
    }
    else {
      let removedIDArr = [...readMoreMSI]
      removedIDArr = removedIDArr.filter( msgID => msgID !== id)
      setReadMoreMSI(removedIDArr)
    }
  }

  const showMoreCharts = id => {
    if(!showCharts.includes(id)){
      setShowCharts(prevArr => [...prevArr, id])
    }
    else {
      let removedIDArr = [...readMoreMSI]
      removedIDArr = removedIDArr.filter( msgID => msgID !== id)
      setShowCharts(removedIDArr)
    }
  }

  const handleRefreshClick = () => {
    dispatch(setLoading(true))
    setRefreshClicked(true)
    
    refetchAlerts()

    setTimeout(() => {
      setRefreshClicked(false)
    }, 1000);
  }

  const alertCardClicked = card => {
    dispatch(setSelectedAlertCard(card))
  }

  return (
    <Detector
      onChange={e => dispatch(setHasInternet(e))}
      render={({online}) => (
        <div className='h-100'>
            <div className='center justify-content-between mb-3'>
              <Button
                variant='contained'
                style={{width: isTripPaused ? '40%' : "100%"}}
                onClick={() => navigate(`/manage-trip/edit-active:${startTripDetails.id}`)}
              >
                Edit {isTripPaused ? "" : "Trip"}
              </Button>

              {isTripPaused &&
                <>
                  <Button
                    variant='contained'
                    color="warning"
                    style={{width: '40%'}}
                    onClick={() => handleTripStatus(props, dispatch, navigate, RESUME_TRIP, "", startTripDetails.id)}
                  >
                    Resume
                  </Button>
                  <Tooltip arrow title="Refresh trip alerts" enterDelay={500}>
                    <RefreshCw 
                      className={'ms-2 refresh ' + (refreshClicked ? "spin" : "")}
                      size={32}
                      color={PRIMARY_COLOR}
                      onClick={handleRefreshClick}
                    />
                  </Tooltip>
                </>
              }

              {!online &&
                <>
                  <Tooltip arrow title="Network is currently offline" >
                    <WifiOff width={"20%"} color="#CD2C2C" size={24}/>
                  </Tooltip>
                </>
              } 
            </div>

          { tripAlerts.length > 0 &&
            <div className='trip-alerts'>
              {tripAlerts.map((d, key) => (
                <div 
                  id={d.id}
                  key={key} 
                  onClick={() => alertCardClicked(d)}
                  className={'trip-alert-card ' + d.severity + (selectedAlert.id === d.id ? " card-selected" : "") + (colorBlind ? " colorBlind" : "")}
                >
                  <div className='center justify-content-start mb-3'>
                    {alertIcon(d.type, d.severity)}
                    {d.mile}
                  </div>
                  <h5 style={{opacity: 0.9}}><b>{d.title}</b></h5>
                  {d.clearanceAtEta &&
                    <h5 className='pt-3'>{
                      unitMeasurement === 'ft'? 
                      <b>{d.clearanceAtEta.toFixed(2)} ft.</b>: 
                      <b>{ftTom(d.clearanceAtEta).toFixed(2)} m.</b>
                      }
                    </h5>
                  }
                  <p className={'trip-alert-msg pt-3 ' + (readMoreMSI.includes(d.id) ? 'read-clicked' : "")}>{d.message}</p>
                  {d.type === MSI_ALERT_TYPE && 
                    <div className='trip-alert-msi-read'>
                      <div className='trip-alert-msi-read-word' onClick={() => showMoreMSI(d.id)}>
                        <small>Show {readMoreMSI.includes(d.id) ? "Less" : "More"}</small>
                      </div>
                    </div>
                  }
                  <div className='pt-3'>
                    ETA: {calculateEtaHoursAndMinutes(moment.now(), d.eta)}
                  </div>

                  {(d.type === BRIDGE_ALERT_TYPE || d.type === RIVER_ALERT_TYPE) &&
                    (d.dt ?
                      <div className='trip-alert-msi-read'>
                        <div
                          className='trip-alert-msi-read-word'
                          onClick={() => showMoreCharts(d.id)}
                        >
                          <small>{showCharts.includes(d.id) ? "Hide" : "Show"} chart</small>
                        </div>
                      </div>
                      :
                      <div className='trip-alert-msi-read no-chart'>
                        <small>No chart available</small>
                      </div>
                    )
                  }

                  {showCharts.includes(d.id) &&
                    <div className='mt-3'>
                      {(d.type === BRIDGE_ALERT_TYPE || d.type === RIVER_ALERT_TYPE) && d.dt &&
                        <Chart data={d}/>
                      }
                    </div>
                  }
                </div>
              ))}
            </div>
          }

          {showEta && startTripDetails.destinations.length > 0 &&
            <div className={'trip-modal-eta ' + (isTripPaused ? "bg-danger" : "bg-primary")}>
              {startTripDetails.origin.city ?? startTripDetails.origin.name}
              {" "}to{" "}
              {startTripDetails.destinations.at(-1).destination.city?.toUpperCase() ?? startTripDetails.destinations.at(-1).destination.name?.toUpperCase()}
              {" "}{isTripPaused && <>&#40; TRIP PAUSED &#41;</>}
              <div>ETA: {showEta}</div>
            </div>
          }
        </div>
      )}
    />
  )
}

export default withApollo(StartTrip)