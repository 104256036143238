import { gql } from "@apollo/client";

export const LOGIN_AUTH = gql`
  mutation ($email: String, $username: String, $password: String!) {
    tokenAuth(email: $email, username: $username, password: $password) {
      token
      refreshToken
      success
      errors
      user {
        id
        company {
          id
          name
          users {
            id
            username
            email
            role
            firstName
            lastName
          }
          vessels {
            id
            name
            mmsi
            state
          }
        }
        email
        username
        role
        firstName
        lastName
      }
    }
  }
`;

export const FORGET_PASSWORD_MUTATION = gql`
  mutation ($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

export const GET_TRIPS = gql`
  query ($minDate: DateTime){
    getTrips(isActive: false, minDate: $minDate) {
      id
      name
      maxDraft
      maxHeight
      origin {
        id
        name
        geo
        city
      }
      createdBy {
        username
        firstName
        lastName
      }
      isActive
      statuses {
        datetime
        datetime
        status
        reason
      }
      destinations {
        destination {
          id
          name
          geo
          city
        }
        arrivedAt
        legNumber
        eta
        trip {
          etaAlerts {
            eta
            message
          }
        }
      }
      departureDate
      createdAt
      positionUpdatedAt
      vessel {
        id
        mmsi
        name
        state
      }
    }
  }
`;

export const GET_VESSELS = gql`
  query {
    getVessels {
      id
      name
      mmsi
      state
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($token: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordReset(
      token: $token
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($oldPassword: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordChange(
      oldPassword: $oldPassword
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
    }
  }
`;

export const ADD_TRIP = gql`
  mutation (
    $name: String!
    $origin: Int!
    $destinations: [Int]!
    $vesselId: UUID!
    $departureDate: DateTime!
    $maxDraft: Float
    $maxHeight: Float
    $user: UUID
  ) {
    createTrip(
      name: $name
      origin: $origin
      destinations: $destinations
      vesselId: $vesselId
      departureDate: $departureDate
      maxDraft: $maxDraft
      maxHeight: $maxHeight
      user: $user
    ) {
      trip {
        id
        name
        origin {
          id
          name
          geo
          city
        }
        maxDraft
        maxHeight
        currentPosition
        positionUpdatedAt
        vessel {
          id
          company {
            id
            name
            address
            users {
              id
              username
              email
              role
            }
            vessels {
              id
              mmsi
              name
              state
            }
          }
          mmsi
          name
        }
        departureDate
        createdAt
        isActive
        statuses {
          datetime
          reason
          status
          id
        }
        destinations {
          destination {
            id
            name
            geo
            city
          }
          arrivedAt
          eta
          trip {
            etaAlerts {
              eta
              message
            }
          }
          legNumber
        }
        user {
          id
          username
          email
          role
          firstName
          lastName
        }
        riverHeightAlerts {
          active
          id
          title
          message
          severity
          clearanceAtEta
          clearance
          dt
          station
          location
          mile
          sortOrder
          eta
        }

        bagAlerts {
          active
          id
          title
          message
          severity
          clearanceAtEta
          clearance
          dt
          bridgeId
          location
          mile
          sortOrder
          eta
          predictions
        }

        msiAlerts {
          active
          id
          title
          message
          severity
          source
          noticeId
          startDate
          endDate
          mile
          location
          sortOrder
          eta
        }

        lockAlerts {
          active
          id
          title
          message
          severity
          lockName
          waterway
          queueLength
          avgDelay
          queueLength
          lockId
          mile
          location
          sortOrder
          eta
        }
      }
    }
  }
`;
export const CALCULATE_TRIP = gql`
  query ($origin: Int!, $destinations: [Int]!, $departureDate: DateTime!) {
    calculateTrip(
      departureDate: $departureDate
      destinations: $destinations
      origin: $origin
    ) {
      eta
    }
  }
`;

export const EDIT_TRIP = gql`
  mutation (
    $origin: Int!
    $destinations: [Int]!
    $departureDate: DateTime!
    $id: UUID!
    $name: String
    $vesselId: UUID
    $maxDraft: Float
    $maxHeight: Float
    $user: UUID
  ) {
    editTrip(
      destinations: $destinations
      id: $id
      name: $name
      origin: $origin
      vesselId: $vesselId
      departureDate: $departureDate
      maxDraft: $maxDraft
      maxHeight: $maxHeight
      user: $user
    ) {
      trip {
        id
        name
        origin {
          id
          name
          geo
          city
        }
        maxDraft
        maxHeight
        currentPosition
        positionUpdatedAt
        vessel {
          id
          company {
            id
            name
            address
            users {
              id
              username
              email
              role
            }
            vessels {
              id
              mmsi
              name
              state
            }
          }
          mmsi
          name
        }
        departureDate
        createdAt
        isActive
        statuses {
          datetime
          reason
          status
          id
        }
        destinations {
          destination {
            id
            name
            geo
            city
          }
          arrivedAt
          eta
          trip {
            etaAlerts {
              eta
              message
            }
          }
          legNumber
        }
        user {
          id
          username
          email
          role
          firstName
          lastName
        }
        riverHeightAlerts {
          active
          id
          title
          message
          severity
          clearanceAtEta
          clearance
          dt
          station
          location
          mile
          sortOrder
          eta
        }

        bagAlerts {
          active
          id
          title
          message
          severity
          clearanceAtEta
          clearance
          dt
          bridgeId
          location
          mile
          sortOrder
          eta
          predictions
        }

        msiAlerts {
          active
          id
          title
          message
          severity
          source
          noticeId
          startDate
          endDate
          mile
          location
          sortOrder
          eta
        }

        lockAlerts {
          active
          id
          title
          message
          severity
          lockName
          waterway
          queueLength
          avgDelay
          queueLength
          lockId
          mile
          location
          sortOrder
          eta
        }
      }
    }
  }
`;

export const CREATE_TRIP_STATUS = gql`
  mutation ($reason: String, $status: StatusTypes!, $tripId: UUID!) {
    createStatus(reason: $reason, status: $status, tripId: $tripId) {
      status {
        status
        reason
      }
    }
  }
`;

export const START_TRIP = gql`
  mutation ($id: UUID!, $isActive: Boolean) {
    editTrip(id: $id, isActive: $isActive) {
      trip {
        id
        name
        user {
          username
          firstName
          lastName
        }
        createdBy {
          username
          firstName
          lastName
        }
        origin {
          id
          name
          geo
          city
        }
        maxDraft
        maxHeight
        vessel {
          id
          mmsi
          name
          state
        }
        createdAt
        isActive
        currentPosition
        departureDate
        positionUpdatedAt
        statuses {
          datetime
          datetime
          status
          reason
        }
        destinations {
          destination {
            id
            name
            geo
            city
          }
          arrivedAt
          eta
          trip {
            etaAlerts {
              eta
              message
            }
          }
          legNumber
        }
        riverHeightAlerts {
          active
          id
          title
          message
          severity
          clearanceAtEta
          clearance
          dt
          station
          location
          mile
          sortOrder
          eta
        }

        bagAlerts {
          active
          id
          title
          message
          severity
          clearanceAtEta
          clearance
          dt
          bridgeId
          location
          mile
          sortOrder
          eta
          predictions
        }

        msiAlerts {
          active
          id
          title
          message
          severity
          source
          noticeId
          startDate
          endDate
          mile
          location
          sortOrder
          eta
        }

        lockAlerts {
          active
          id
          title
          message
          severity
          lockName
          waterway
          queueLength
          avgDelay
          queueLength
          lockId
          mile
          location
          sortOrder
          eta
        }
      }
    }
  }
`;
export const GET_TRAFFIC = gql`
  query ($origin: Geometry!, $destinations: [Int]!) {
    getTraffic(origin: $origin, destinations: $destinations) {
      color
      overallAvg
      avgRatio
      id
    }
  }
`;

export const GET_LOCATION_SEARCH = gql`
  query ($text: String!, $swCorner: Geometry, $neCorner: Geometry) {
    getLocations(text: $text, swCorner: $swCorner, neCorner: $neCorner) {
      latitude
      longitude
      id
      name
      geo
      mile
      city
      waterway
    }
  }
`;

export const GET_ACTIVE_TRIPS = gql`
  query {
    getTrips(isActive: true) {
      id
      name
      user {
        username
        firstName
        lastName
      }
      createdBy {
        username
        firstName
        lastName
      }
      origin {
        id
        name
        geo
        city
      }
      maxDraft
      maxHeight
      vessel {
        id
        mmsi
        name
        state
      }
      createdAt
      isActive
      currentPosition
      departureDate
      positionUpdatedAt
      statuses {
        datetime
        datetime
        status
        reason
      }
      destinations {
        destination {
          id
          name
          geo
          city
        }
        arrivedAt
        eta
        trip {
          etaAlerts {
            eta
            message
          }
        }
        legNumber
      }
      riverHeightAlerts {
        active
        id
        title
        message
        severity
        clearanceAtEta
        clearance
        dt
        station
        location
        mile
        sortOrder
        eta
      }

      bagAlerts {
        active
        id
        title
        message
        severity
        clearanceAtEta
        clearance
        dt
        bridgeId
        location
        mile
        sortOrder
        eta
        predictions
      }

      msiAlerts {
        active
        id
        title
        message
        severity
        source
        noticeId
        startDate
        endDate
        mile
        location
        sortOrder
        eta
      }

      lockAlerts {
        active
        id
        title
        message
        severity
        lockName
        waterway
        queueLength
        avgDelay
        queueLength
        lockId
        mile
        location
        sortOrder
        eta
      }
    }
  }
`;

export const START_TRIP_SUBSCRIPTION = gql`
  subscription OnTripSubscription($id: UUID!) {
    tripSubscription(id: $id) {
      mileMarkers {
        waterway
        lat
        long
        mile
        distance
      }
      riverHeightAlerts {
        active
        id
        title
        message
        severity
        clearanceAtEta
        clearance
        dt
        clearance
        station
        location
        mile
        sortOrder
        eta
        createdAt
      }
      bagAlerts {
        active
        id
        title
        message
        severity
        clearanceAtEta
        clearance
        dt
        bridgeId
        location
        mile
        sortOrder
        eta
        createdAt
        predictions
      }
      lockAlerts {
        active
        id
        title
        message
        severity
        lockName
        waterway
        queueLength
        avgDelay
        queueLength
        lockId
        location
        mile
        sortOrder
        eta
        createdAt
      }

      msiAlerts {
        active
        id
        title
        message
        severity
        source
        noticeId
        startDate
        endDate
        sortOrder
        mile
        location
        eta
        createdAt
      }
      trip {
        id
        name
        origin {
          id
          name
          geo
          city
        }
        maxDraft
        maxHeight
        currentPosition
        positionUpdatedAt
        vessel {
          id
          company {
            id
            name
            address
            users {
              id
              username
              email
              role
            }
            vessels {
              id
              mmsi
              name
              state
            }
          }
          mmsi
          name
        }
        departureDate
        createdAt
        isActive
        statuses {
          datetime
          reason
          status
        }
        destinations {
          destination {
            id
            name
            geo
            city
          }
          arrivedAt
          legNumber
          eta
          trip {
            etaAlerts {
              eta
              message
            }
          }
        }
        riverHeightAlerts {
          active
          id
          title
          message
          severity
          clearanceAtEta
          clearance
          dt
          clearance
          station
          location
          mile
          sortOrder
          eta
          createdAt
        }
        bagAlerts {
          active
          id
          title
          message
          severity
          clearanceAtEta
          clearance
          dt
          bridgeId
          location
          mile
          sortOrder
          eta
          createdAt
          predictions
        }
        lockAlerts {
          active
          id
          title
          message
          severity
          lockName
          waterway
          queueLength
          avgDelay
          queueLength
          lockId
          location
          mile
          sortOrder
          eta
          createdAt
        }
  
        msiAlerts {
          active
          id
          title
          message
          severity
          source
          noticeId
          startDate
          endDate
          sortOrder
          mile
          location
          eta
          createdAt
        }
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation ($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      errors
      success
      payload
    }
  }
`;

export const CSV_DATA_DECKLOGS = gql`
  query ($tripId: UUID!) {
    getDecklogs(tripId: $tripId) {
      datetime
      lat
      long
      waterway
      mile
      description
    }
  }
`;

export const CSV_DATA_PREVOYAGE = gql`
  query ($tripId: UUID!) {
    getPrevoyagePlan(tripId: $tripId) {
      datetime
      lat
      long
      waterway
      mile
      description
      eta
      alertType
      severity
    }
  }
`;

export const REFRESH_ALERTS = gql`
  query ($tripId: UUID!) {
    refreshAlerts(tripId: $tripId) {
      trip {
        id
        name
        origin {
          id
          name
          geo
        }
        maxDraft
        maxHeight
        vessel {
          id
          company {
            id
            name
            address
            users {
              id
              username
              email
              role
            }
            vessels {
              id
              mmsi
              name
              state
            }
          }
          mmsi
          name
        }
        departureDate
        createdAt
        isActive
        statuses {
          datetime
          reason
          status
        }
        destinations {
          destination {
            id
            name
            geo
          }
          arrivedAt
          eta
          trip {
            etaAlerts {
              eta
              message
            }
          }
        }
      }
      riverHeightAlerts {
        active
        id
        title
        message
        severity
        clearanceAtEta
        clearance
        dt
        station
        location
        mile
        sortOrder
        eta
      }
      bagAlerts {
        active
        id
        title
        message
        severity
        clearanceAtEta
        clearance
        dt
        bridgeId
        location
        mile
        sortOrder
        eta
        predictions
      }
      lockAlerts {
        active
        id
        title
        message
        severity
        lockName
        waterway
        queueLength
        avgDelay
        queueLength
        lockId
        mile
        location
        sortOrder
        eta
      }
      msiAlerts {
        active
        id
        title
        message
        severity
        source
        noticeId
        startDate
        endDate
        mile
        location
        sortOrder
        eta
      }
    }
  }
`;

export const GET_LOCATION = gql`
  query ($text: String) {
    getLocations(text: $text) {
      id
      facilityType
      name
      latitude
      longitude
      city
      waterway
      mile
      geo
    }
  }
`;

export const FETCH_ALERTS = gql`
  query ($tripId: UUID!) {
    fetchAlerts(tripId: $tripId) {
      success
    }
  }
`

export const GET_MILE_PATH = gql`
  query ($tripId: UUID!) {
    getMilePath(tripId: $tripId) {
      id
      waterway
      mile
      lat
      long
      distance
      name
    }
  }
`