import { Button } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function ReturnToCurrentTrip() {
  const navigate = useNavigate()
  const tripId = useSelector(state => state.startTripDetails.id)
  const isTripPaused = useSelector(state => state.isTripPaused)

  return (
    <Button
      className='mb-3'
      variant='contained'
      fullWidth
      color='warning'
      onClick={() => navigate(`/start-trip/${tripId}`)}
    >
      Return to current trip {isTripPaused && <>&#40; TRIP PAUSED &#41;</>}
    </Button>
  )
}
